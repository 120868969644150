import { sub, subHours } from "date-fns";

export function cleanProperties(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

// Função para extrair hh:mm de uma string de data
export function getHoursMinuts(dataString: string): string {
  // Criar um objeto Date a partir da string
  const data = subHours(new Date(dataString), 3);

  // Obter horas e minutos
  const horas = String(data.getUTCHours()).padStart(2, "0");
  const minutos = String(data.getUTCMinutes()).padStart(2, "0");

  // Retornar no formato desejado (hh:mm)
  return `${horas}:${minutos}`;
}

export function dateFormat(dataString?: string): string {
  if (dataString) {
    // Criar um objeto Date a partir da string
    const data = subHours(new Date(dataString), 3);

    // Obter dia, mês, ano, horas, minutos e segundos
    const dia = String(data.getUTCDate()).padStart(2, "0");
    const mes = String(data.getUTCMonth() + 1).padStart(2, "0"); // Mês começa do zero
    const ano = String(data.getUTCFullYear());
    const horas = String(data.getUTCHours()).padStart(2, "0");
    const minutos = String(data.getUTCMinutes()).padStart(2, "0");
    const segundos = String(data.getUTCSeconds()).padStart(2, "0");

    // Montar a string formatada
    const dataFormatada = `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;

    return dataFormatada;
  } else {
    return "";
  }
}
export function validatorEmail(email) {
  // Expressão regular para validar o email
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function sumTimes(tempo1, tempo2) {
  if (tempo1 && tempo2) {
    var array1 = tempo1.split(":");

    var tempo_seg1: any =
      parseInt(array1[0]) * 3600 +
      parseInt(array1[1]) * 60 +
      parseInt(array1[2]);

    var array2 = tempo2.split(":");

    var tempo_seg2: any =
      parseInt(array2[0]) * 3600 +
      parseInt(array2[1]) * 60 +
      parseInt(array2[2]);

    var tempofinal = parseInt(tempo_seg1) + parseInt(tempo_seg2);

    var hours = Math.floor(tempofinal / (60 * 60));

    if (hours > 24) hours = hours - 24;

    var divisorMinutos = tempofinal % (60 * 60);

    var minutes = Math.floor(divisorMinutos / 60);
    if (minutes > 60) {
      minutes = minutes - 60;
      hours = hours + 1;
    }

    var divisorSeconds = divisorMinutos % 60;

    var seconds = Math.ceil(divisorSeconds);

    var contador = "";

    if (hours < 10) {
      contador = "0" + hours + ":";
    } else {
      contador = hours + ":";
    }

    if (minutes < 10) {
      contador += "0" + minutes + ":";
    } else {
      contador += minutes + ":";
    }

    if (seconds < 10) {
      contador += "0" + seconds;
    } else {
      contador += seconds;
    }

    return contador;
  }
}

export function formatDate(data: string) {
  if (data) {
    var array = data.split("");
    var year = array[0] + array[1] + array[2] + array[3];
    var month = array[5] + array[6];
    var day = array[8] + array[9];
    var hour = array[11] + array[12];

    var hourInt = parseInt(hour) - 3;

    if (hourInt < 0) {
      hourInt = hourInt + 24;
      day = (Number(day) - 1).toString();
    }
    var hour = hourInt.toLocaleString();

    var minute = array[14] + array[15];
    var sec = array[17] + array[18];

    return (
      (Number(day) <= 9 ? "0" + Number(day) : day) +
      "/" +
      month +
      "/" +
      year +
      " " +
      (Number(hour) <= 9 ? "0" + Number(hour) : hour) +
      ":" +
      minute +
      ":" +
      (Number(sec) <= 9 ? "0" + Number(sec) : sec)
    );
  }
}

export function formatHour(data) {
  var dataHora = data.split(":");
  var segundos = dataHora[2].split(".");
  return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
}
