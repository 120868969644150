import { Component, Inject, OnInit, TemplateRef } from "@angular/core";
import {
  addHours,
  addMinutes,
  format,
  getDate,
  getMonth,
  isAfter,
  isBefore,
  subHours,
  subMinutes,
  addDays,
  setDate,
  set,
  isLastDayOfMonth,
} from "date-fns";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { ptBR } from "date-fns/locale";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { EditRegistroComponent } from "../editregistro/editregistro.component";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { ReviewService } from "src/app/_services/review.service";
import { GridService } from "src/app/_services/grid.service";
import { ScheduleService } from "src/app/_services/schedule.service";
import { ConsolidatedShiftService } from "../../_services/consolidated-shift.service";
import { WorkSlotService } from "../../_services/work-slot.service";
import { ScheduleMonitoringModalComponent } from "../schedule-monitoring-modal/schedule-monitoring-modal.component";
import { SCHEDULE_MONITORING_MODAL_TOKEN } from "../schedule-monitoring-modal/schedule-monitoring-modal.token";
import { Review } from "src/app/_models/review.model";
import { getHoursMinuts } from "src/utils";
import { dateFormat } from "../../../utils";
export class ConsolidatedShift {
  health_professional_name: string;
  start_datetime: Date;
  end_datetime: Date;
  worked_hours: Date;
}

@Component({
  selector: "medilog-schedule-monitoring",
  templateUrl: "./schedule-monitoring.component.html",
  styleUrls: ["./schedule-monitoring.component.scss"],
})
export class ScheduleMonitoringComponent implements OnInit {
  legends = [
    {
      name: "Registro realizado dentro do horário previsto em escala (com tolerância máxima de 15 minutos para entradas/saídas antecipadas/atrasadas).",
      color: "#025A7F",
    },
    {
      name: "Registro realizado com horário diferente do previsto em escala e acima da tolerância aplicada.",
      color: "#ffff00",
    },
    { name: "Registro iniciado, jornada em andamento.", color: "#17ce17" },
    { name: "Registro não iniciado, jornada em andamento.", color: "#ff6600" },
    { name: "Registro não realizado.", color: "#ff0000" },
  ];
  isOpen = false;
  title: string;
  healthUnits: any;
  selectedUnit: any;
  scheduleResult: any;
  selectedGrade: any = {
    name: "Selecione a Grade",
    id: null,
  };
  healthProfessionals: any = {
    results: [],
  };
  selectedProfessional: any = {
    name: "Todos os Profissionais",
    user_type: 0,
    email: 0,
    id: null,
  };
  loading: boolean = true;
  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  pt: any;
  gradeAllData: any;
  gradeData: any = {
    results: [],
  };
  apontamentoFora = false;
  apontamentoDentro = false;
  cols: any[];
  consolidatedShift = [];
  externalSchedule = [];
  externalConsolidatedShift: number = 0;
  schedule: any;
  showUnit: string = "Selecione a Unidade";
  showSchedule: string = "Selecione a Grade";
  showProfessional: string = "Todos os Profissionais";
  showMonth: string = "Selecione o Mês";
  countTurnos: any;
  gridId: any;
  realMonthText: string;
  realMonth: string;
  mesAtivo: number;
  yearSchedule: number;
  datesOfMonth: any;
  gradeHours: any;
  myInnerHeight: number;
  rowWeek: any;
  scheduleHistory: any;
  workSlotData: any;
  workSlot: any;
  healthUnitsProfissionais = [];
  showTable: boolean;
  report: any;
  resultSchedule: any;
  originalConsolidatedShift;
  greenProfessionals: any[] = [];

  constructor(
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private reviewService: ReviewService,
    private gridService: GridService,
    private scheduleService: ScheduleService,
    private consolidatedShiftService: ConsolidatedShiftService,
    private workSlotService: WorkSlotService,
    public dialog: MatDialog,
    @Inject(SCHEDULE_MONITORING_MODAL_TOKEN)
    private scheduleMonitoringModalComponent: TemplateRef<ScheduleMonitoringModalComponent>
  ) {
    document.getElementById("menu").click();
  }

  async ngOnInit() {
    this.cols = [
      { field: "nome", header: "Nome" },
      { field: "entrada", header: "Entrada" },
      { field: "saida", header: "Saida" },
      { field: "total", header: "Total" },
      { field: "acoes", header: "Ações" },
    ];
    this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    this.healthProfessionals.results.push({
      name: "Todos os Profissionais",
      user_type: 0,
      email: 0,
      id: null,
    });

    this.loading = true;

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };

    //Get title page
    this.title = localStorage.getItem("titulo");
    this.getHealthUnits();
  }

  //Busca as grades para o select
  async getGrid() {
    this.rowWeek = [];
    this.consolidatedShift = [];
    this.gradeAllData = "";
    if (this.selectedUnit.id == 0) {
      let dat = { results: [] };
      this.gradeData = dat;
      this.gradeData?.results.push({ name: "Selecione a Grade", id: 0 });
      this.loading = false;
    } else {
      this.loading = true;
      await this.gridService
        .getGrids(this.selectedUnit.id)
        .subscribe(async (data) => {
          this.gradeData = data;
          // if (this.gradeData.count === 1) {
          //   this.selectedGrade = this.gradeData.results[0];
          //   await this.changeGrid();
          // }
          this.gradeData.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.gradeData.results.unshift({ name: "Selecione a Grade", id: 0 });
          this.loading = false;
        });
    }
  }

  async getProfissionais(
    occupation_area?: string,
    areas_of_expertise?: string[]
  ) {
    this.loading = true;
    if (occupation_area) {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory(
          occupation_area,
          areas_of_expertise
        )
        .subscribe((data) => {
          this.healthProfessionals = data;

          this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.healthProfessionals.results.map((professional) => {
            this.healthUnitsProfissionais.push(professional);
          });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });

          // if (this.selectedGrade.id) {
          //   this.requestData()
          // }
          this.loading = false;
        });
    } else {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory()
        .subscribe((data) => {
          this.healthProfessionals = data;

          this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.healthProfessionals.results.map((professional) => {
            this.healthUnitsProfissionais.push(professional);
          });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });
          this.loading = false;
        });
    }
  }
  clearTable() {
    this.gradeAllData = "";
    //this.getProfissionais();
  }

  getHealthUnits() {
    this.loading = true;
    const params = {
      health_unit_status: "enable",
    };
    this.healthUnitService.getHealthUnits(params).subscribe(async (data) => {
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });
      this.loading = false;
      //this.healthUnits.results.reverse();
      // await  this.getProfissionais();
    });

    this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
  }

  async requestData() {
    this.loading = true;
    this.externalConsolidatedShift = 0;
    this.externalSchedule = [];
    this.consolidatedShift = [];
    this.rowWeek = [];

    if (this.selectedUnit.id === 0 || !this.selectedGrade) {
      this.openDialog("Informe a Unidade.");
      this.loading = false;
      return;
    }

    if (!this.selectedGrade.id || this.selectedGrade.id === 0) {
      this.openDialog("Informe a Grade.");
      this.loading = false;
      return;
    }
    if (!this.modelDataInicio) {
      this.openDialog("Informe o mês.");
      this.loading = false;
      return;
    }

    //this.loading = true;
    //this.requestScheduleData();
    await this.requestConsolidatedShift();
  }
  async requestConsolidatedShift() {
    this.countTurnos = 0;
    this.showUnit = this.selectedUnit.name;
    this.showSchedule = this.selectedGrade.name;
    this.showProfessional = this.selectedProfessional.name;
    this.showMonth = format(this.modelDataInicio, "LLLL", { locale: ptBR });
    if (this.showMonth)
      this.showMonth =
        this.showMonth[0].toUpperCase() + this.showMonth.substring(1);

    var datas = setDate(new Date(this.modelDataInicio), 1);
    var date = datas.toISOString().split("T");

    // await this.healthProfessionalService.getGridId(this.selectedGrade.id).subscribe(async (data) => {
    this.gradeAllData = this.selectedGrade;

    this.countTurnos = this.gradeAllData.planned_workshifts.length;

    await this.mountSchedule(this.gradeAllData.id);
    // });
  }
  openAndClosePanelFora() {
    if (!this.apontamentoFora) {
      this.apontamentoFora = true;
    } else {
      this.apontamentoFora = false;
    }
  }
  openAndClosePanelDentro() {
    if (!this.apontamentoDentro) {
      this.apontamentoDentro = true;
    } else {
      this.apontamentoDentro = false;
    }
  }
  getDiaAtivo(dia) {
    if (this.gradeAllData) {
      if (dia == "0") {
        return this.gradeAllData.days_of_week.sunday;
      } else if (dia == "1") {
        return this.gradeAllData.days_of_week.monday;
      } else if (dia == "2") {
        return this.gradeAllData.days_of_week.tuesday;
      } else if (dia == "3") {
        return this.gradeAllData.days_of_week.wednesday;
      } else if (dia == "4") {
        return this.gradeAllData.days_of_week.thursday;
      } else if (dia == "5") {
        return this.gradeAllData.days_of_week.friday;
      } else {
        return this.gradeAllData.days_of_week.saturday;
      }
    } else {
      return;
    }
  }
  async mountSchedule(idGrid) {
    this.loading = true;
    const dateFormat = this.modelDataInicio.toISOString().split("T");

    await this.scheduleService
      .getSchedule(idGrid, this.selectedUnit.id, dateFormat[0], true)
      .subscribe(
        async (data) => {
          var dataInicio = new Date(this.modelDataInicio);
          var date = dataInicio.toISOString().split("T");
          this.schedule = data;

          if (!this.schedule) {
            this.openDialog("Não foram encontrados registros");
            this.loading = false;
            return;
          }
          this.resultSchedule = this.schedule;

          this.resultSchedule = data;

          this.gridId = data.id;

          this.loading = false;

          const dateFormat = setDate(new Date(this.modelDataInicio), 1)
            .toISOString()
            .split("T");

          this.resultSchedule = this.schedule;

          if (
            this.resultSchedule.current_grid &&
            this.selectedGrade.history.length > 0
          ) {
            const datagrid = this.selectedGrade.history.find(
              (grid) => grid.id === this.schedule.current_grid
            );

            if (datagrid)
              this.gradeAllData = { ...datagrid, id: this.gradeAllData.id };
          } else {
            const datagrid = this.selectedGrade.history.find(
              (grid) => grid.id === this.schedule.grid
            );

            if (datagrid) {
              this.gradeAllData = { ...datagrid, id: this.gradeAllData.id };
            } else {
              this.gradeAllData = {
                ...this.selectedGrade,
                id: this.selectedGrade.id,
              };
            }
          }

          let datas: any = {
            health_unit_id: this.selectedUnit.id,
            date: dateFormat[0],
            grid_id: this.selectedGrade.id,
            filter_work_slot: true,
          };
          if (this.selectedProfessional.health_professional_id)
            datas.health_professional =
              this.selectedProfessional.health_professional_id;
          this.loading = true;

          await this.reviewService.getAllReview(datas).subscribe(
            (result) => {
              this.loading = false;

              //verifica se esta no horario

              this.consolidatedShift = result;
              this.originalConsolidatedShift = result;

              for (let check of this.consolidatedShift) {
                if (!check.duration) {
                  check.duration = "00:00:00";
                }
                if (
                  !check.start_datetime &&
                  !check.adjusted_start_datetime &&
                  !check.end_datetime &&
                  !check.adjusted_end_datetime
                ) {
                  check.status = "NOREPORT";
                  continue;
                }
                if (
                  (check.adjusted_start_datetime || check.start_datetime) &&
                  !check.end_datetime &&
                  !check.adjusted_end_datetime
                ) {
                  check.status = "INPROGRESS";
                  continue;
                }
              }

              this.mountTurno(
                this.gradeAllData.planned_workshifts,
                this.schedule.month
              );
            },
            () => {
              this.report = {
                horas_previstas: "00:00:00",
                valor_previsto: 0,
                horas_executadas: "00:00:00",
                valor_pagar: 0,
                unidade: "-",
                grade: "-",
                mes_vigencia: "-",
                categoria_profissional: "-",
                profissionais: "-",
                turnos: 0,
                apontamentos_fora_escala: "-",
                apontamentos_dentro_escala: "-",
                dh_criacao: null,
                dh_alteracao: null,
                totalProfessionalReport: [],
                quantity: 0,
                professionais: [],
              };
              this.openDialog("Não foram encontrados registros");
              this.loading = false;
            }
          );
        },
        () => {
          this.report = {
            horas_previstas: "00:00:00",
            valor_previsto: 0,
            horas_executadas: "00:00:00",
            valor_pagar: 0,
            unidade: "-",
            grade: "-",
            mes_vigencia: "-",
            categoria_profissional: "-",
            profissionais: "-",
            turnos: 0,
            apontamentos_fora_escala: "-",
            apontamentos_dentro_escala: "-",
            dh_criacao: null,
            dh_alteracao: null,
            totalProfessionalReport: [],
            quantity: 0,
            professionais: [],
          };
          this.loading = false;
          this.openDialog("Não foram encontrados registros");
        }
      );
  }

  async mountTurno(planned_workshifts, mes) {
    var mesPlanejado = new Date(mes.replace("-", "/").replace("-", "/"));

    this.formatMonth(mesPlanejado.getMonth());
    this.formatMonthText(mesPlanejado.getMonth());
    this.mesAtivo = mesPlanejado.getMonth();
    this.yearSchedule = mesPlanejado.getFullYear();
    this.datesOfMonth = this.getWeeksInMonth(
      mesPlanejado.getFullYear(),
      mesPlanejado.getMonth(),
      planned_workshifts
    );

    let newDates = [];
    for (var i = 0; i < this.datesOfMonth[0].diasprof.length; i++) {
      newDates.push(this.datesOfMonth[0].diasprof[i].dia);
    }
    this.datesOfMonth[0].dates = newDates;

    const date = getMonth(mesPlanejado);
    this.rowWeek = [];
    this.rowWeek = await this.getWeeksInMonthNew(
      mesPlanejado.getFullYear(),
      date,
      planned_workshifts
    );

    this.getWorkSlot(this.schedule.id);
    this.gradeHours = [];

    for (var s = 0; s < planned_workshifts.length; s++) {
      var starts = planned_workshifts[s].start_time.split(":");

      if (s == planned_workshifts.length - 1) {
        // this.loading = true;

        // this.healthProfessionalService

        //   .getWorkSlotById(this.schedule.id)
        //   .subscribe((data) => {
        //     const schedule: Schedule = data;

        await this.mountEditSchedule(this.scheduleHistory);
        // });
      }
    }
  }
  formatMonth(mes) {
    if (mes == 0) {
      this.realMonth = "01";
    } else if (mes == 1) {
      this.realMonth = "02";
    } else if (mes == 2) {
      this.realMonth = "03";
    } else if (mes == 3) {
      this.realMonth = "04";
    } else if (mes == 4) {
      this.realMonth = "05";
    } else if (mes == 5) {
      this.realMonth = "06";
    } else if (mes == 6) {
      this.realMonth = "07";
    } else if (mes == 7) {
      this.realMonth = "08";
    } else if (mes == 8) {
      this.realMonth = "09";
    } else if (mes == 9) {
      this.realMonth = "10";
    } else if (mes == 10) {
      this.realMonth = "11";
    } else {
      this.realMonth = "12";
    }
  }

  formatMonthText(mes) {
    if (mes == 0) {
      this.realMonthText = "Janeiro";
    } else if (mes == 1) {
      this.realMonthText = "Fevereiro";
    } else if (mes == 2) {
      this.realMonthText = "Março";
    } else if (mes == 3) {
      this.realMonthText = "Abril";
    } else if (mes == 4) {
      this.realMonthText = "Maio";
    } else if (mes == 5) {
      this.realMonthText = "Junho";
    } else if (mes == 6) {
      this.realMonthText = "Julho";
    } else if (mes == 7) {
      this.realMonthText = "Agosto";
    } else if (mes == 8) {
      this.realMonthText = "Setembro";
    } else if (mes == 9) {
      this.realMonthText = "Outubro";
    } else if (mes == 10) {
      this.realMonthText = "Novembro";
    } else {
      this.realMonthText = "Dezembro";
    }
  }

  formatDate(data: string) {
    if (data) {
      var array = data.split("");
      var year = array[0] + array[1] + array[2] + array[3];
      var month = array[5] + array[6];
      var day = array[8] + array[9];
      var hour = array[11] + array[12];

      var hourInt = parseInt(hour) - 3;

      if (hourInt < 0) {
        hourInt = hourInt + 24;
        day = (Number(day) - 1).toString();
      }
      var hour = hourInt.toLocaleString();

      var minute = array[14] + array[15];
      var sec = array[17] + array[18];

      return (
        (Number(day) <= 9 ? "0" + Number(day) : day) +
        "/" +
        month +
        "/" +
        year +
        " " +
        (Number(hour) <= 9 ? "0" + Number(hour) : hour) +
        ":" +
        minute +
        ":" +
        (Number(sec) <= 9 ? "0" + Number(sec) : sec)
      );
    }
  }

  getWeeksInMonth(year, month, planned_workshifts) {
    const weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    var tt = weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));

    let arrayNew = [];
    for (var t = 0; t < this.countTurnos; t++) {
      var semanass = weeks
        .filter((w) => !!w.length)
        .map((w) => ({
          start: w[0],
          end: w[w.length - 1],
          dates: w,
        }));

      var firstWeek = tt[0];
      var lastWeek = tt[tt.length - 1];
      var concatFirstWeek = 7 - firstWeek.dates.length;
      var concatLasttWeek = 7 - lastWeek.dates.length;

      var starts = planned_workshifts[t].start_time.split(":");
      if (Number(starts[0]) > 7 && Number(starts[0]) < 19) {
        for (var a = 0; a < semanass.length; a++) {
          var asTurno = semanass;
          asTurno[a]["diasprof"] = [];
          for (var d = 0; d < asTurno[a].dates.length; d++) {
            asTurno[a]["diasprof"].push({
              profs: [],
              dia: asTurno[a].dates[d],
              periodo: "dia",
            });
            for (
              var pp = 0;
              pp < planned_workshifts[0].professionals_count;
              pp++
            ) {
              asTurno[a]["diasprof"][
                asTurno[a]["diasprof"].length - 1
              ].profs.push({ id: "" });
            }
          }
          if (a == asTurno.length - 1) {
            arrayNew.push(asTurno);
          }
        }
      } else {
        for (var i = 0; i < semanass.length; i++) {
          var abTurno = semanass;
          abTurno[i]["diasprof"] = [];
          for (var d = 0; d < abTurno[i].dates.length; d++) {
            abTurno[i]["diasprof"].push({
              profs: [],
              dia: abTurno[i].dates[d],
              periodo: "noite",
            });
            for (
              var pp = 0;
              pp < planned_workshifts[0].professionals_count;
              pp++
            ) {
              abTurno[i]["diasprof"][
                abTurno[i]["diasprof"].length - 1
              ].profs.push({ id: "" });
            }
          }
          if (i == abTurno.length - 1) {
            arrayNew.push(abTurno);
          }
        }
      }

      if (t == this.countTurnos - 1) {
        //return arrayNew;
      }
    }

    for (var i = 0; i < tt.length; i++) {
      var semanas = tt[i].dates;
      tt[i]["diasprof"] = [];
      for (var d = 0; d < tt[i].dates.length; d++) {
        tt[i]["diasprof"].push({ profs: [], dia: tt[i].dates[d] });
        for (var pp = 0; pp < planned_workshifts[0].professionals_count; pp++) {
          tt[i]["diasprof"][tt[i]["diasprof"].length - 1].profs.push({
            id: "",
          });
        }
      }

      if (i == tt.length - 1) {
        for (var i = 0; i < concatFirstWeek; i++) {
          firstWeek["diasprof"].unshift({ profs: [], dia: 0 });
        }
        for (var i = 0; i < concatLasttWeek; i++) {
          lastWeek["diasprof"].push({ profs: [], dia: 0 });
        }
        var dr = [];
        for (var pl = 0; pl < planned_workshifts.length; pl++) {
          dr.push({ periodo: pl, profs: tt });
          if (pl == planned_workshifts.length - 1) {
          }
        }

        return tt;
      }
    }
  }
  getWeeksInMonthNew(year, month, planned_workshifts) {
    const weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    var semanas = weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));

    var firstWeek = semanas[0];
    var lastWeek = semanas[semanas.length - 1];
    var concatFirstWeek = 7 - firstWeek.dates.length;
    var concatLasttWeek = 7 - lastWeek.dates.length;

    var arrayWeek = [];

    for (var s = 0; s < semanas.length; s++) {
      arrayWeek.push({ semana: [] });
      for (var turno = 0; turno < this.countTurnos; turno++) {
        var starts = planned_workshifts[turno].start_time.split(":");
        this.calculateHour(turno);

        const start_time = planned_workshifts[turno].start_time.substring(0, 5);
        const horaFim = planned_workshifts[turno].horaFim.substring(0, 5);

        arrayWeek[s].semana.push({
          id: planned_workshifts[turno].id,
          periodo: planned_workshifts[turno].name,
          periodoLabel: start_time + "-" + horaFim,
          dias: [],
          realStartDate: planned_workshifts[turno].start_time,
          duration: planned_workshifts[turno].duration,
        });

        for (var dia = 0; dia < semanas[s].dates.length; dia++) {
          arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.push({
            dia: semanas[s].dates[dia],
            professionais: [],
          });

          for (
            var prof = 0;
            prof < planned_workshifts[turno].professionals_count;
            prof++
          ) {
            arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias[
              arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.length -
                1
            ].professionais.push({ id: "" });
          }
        }
      }

      if (s == semanas.length - 1) {
        for (var i = 0; i < concatFirstWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[0].semana[s].dias.unshift({ dia: 0, professionais: [] });
          }
        }
        for (var i = 0; i < concatLasttWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[semanas.length - 1].semana[s].dias.push({
              dia: 0,
              professionais: [],
            });
          }
        }
        return arrayWeek;
      }
    }
  }
  calculateHour(position) {
    if (
      this.gradeAllData.planned_workshifts[position].start_time != "" &&
      this.gradeAllData.planned_workshifts[position].duration != ""
    ) {
      this.gradeAllData.planned_workshifts[position].horaFim = this.somartempos(
        this.gradeAllData.planned_workshifts[position].start_time,
        this.gradeAllData.planned_workshifts[position].duration
      );
    }
  }
  //Busca os slots ocupados
  async getWorkSlot(schedule: string) {
    const data = {
      schedule_id: schedule,
    };
    const dateFormat = this.modelDataInicio.toISOString().split("T");

    // this.healthProfessionalService
    //   .getSchedule(this.selectedGrade.id, this.selectedUnit.id, dateFormat[0], true)
    //   .subscribe(async (data) => {
    this.scheduleHistory = this.schedule.history_work_slots;
    this.workSlotData = this.schedule.work_slots_review.length
      ? this.schedule.work_slots_review
      : this.schedule.work_slots;

    // this.loading = true;
    await this.alterDate();

    // this.loading = false;
    // });
  }
  mountEditSchedule(schedules) {
    for (var row = 0; row < this.rowWeek.length; row++) {
      for (var per = 0; per < this.rowWeek[row].semana.length; per++) {
        for (
          var dias = 0;
          dias < this.rowWeek[row].semana[per].dias.length;
          dias++
        ) {
          var dd;

          if (this.rowWeek[row].semana[per].dias[dias].dia < 10) {
            dd = "0" + this.rowWeek[row].semana[per].dias[dias].dia.toString();
          } else {
            dd = this.rowWeek[row].semana[per].dias[dias].dia.toString();
          }

          var fulldate =
            this.yearSchedule +
            "-" +
            this.realMonth +
            "-" +
            dd +
            "T" +
            this.rowWeek[row].semana[per].realStartDate +
            "Z";
        }
      }
    }
  }
  //Formata o rowWeek para adicionar os dados dos profissionais
  async alterDate() {
    this.workSlot = this.workSlotData;
    if (this.workSlot) {
      for (const slot of this.workSlot) {
        //O profissional para adicionar

        let prof = this.healthUnitsProfissionais.find(
          (profissional) =>
            profissional.health_professional_id ===
            slot?.health_professional?.id
        );

        if (!prof) continue;
        console.log(prof, "prof");

        const dia = parseInt(slot.start_date.slice(slot.start_date.length - 2));

        for (var mes = 0; mes < this.rowWeek.length; mes++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              for (
                var profissional: number =
                  this.rowWeek[mes].semana[semana].dias[dias].professionais
                    .length - 1;
                profissional >= 0;
                profissional--
              ) {
                //verifica se o usuario esta escalado no slot
                const userInSlot =
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id === prof.health_professional_id;

                //verifica se o slot verificado esta disponivel
                const emptySlot =
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id === "";

                if (
                  this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                  this.rowWeek[mes].semana[semana].periodo === slot.period
                ) {
                  console.log("passou day period");
                  if (userInSlot) {
                    console.log("1");
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ] = {
                      id: prof.health_professional_id,
                      health_professional_id: prof.health_professional_id,
                      health_professional_name: prof.name,
                      profile: prof.profile,
                      periodo: prof.periodo,
                    };

                    break;
                  } else if (!userInSlot && emptySlot) {
                    console.log("2");
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ] = {
                      id: prof.health_professional_id,
                      health_professional_id: prof.health_professional_id,
                      health_professional_name: prof.name,
                      profile: prof.profile,
                      periodo: prof.periodo,
                    };
                    break;
                  } else if (slot.is_extra) {
                    console.log("3");
                    this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.unshift({
                      id: prof.health_professional_id,
                      health_professional_name: prof.name,
                      health_professional_id: prof.health_professional_id,
                      profile: prof.profile,
                      periodo: prof.periodo,
                      is_extra: prof.is_extra,
                    });
                    break;
                  }
                }

                // if (
                //   !slot.is_extra
                //     ?
                //       this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                //       this.rowWeek[mes].semana[semana].dias[dias].professionais[
                //         profissional
                //       ].id === "" &&
                //       this.rowWeek[mes].semana[semana].periodo ===
                //         slot.period &&
                //       !hasProf
                //     : this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                //       this.rowWeek[mes].semana[semana].periodo === slot.period &&
                // ) {
                //       if( prof.health_professional_id === "7f483ea4-e09b-4d74-9a9c-05e9d6df465b")console.log('antes',prof )
                //   if (slot.is_extra) {
                //         if( prof.health_professional_id === "7f483ea4-e09b-4d74-9a9c-05e9d6df465b")console.log('aqui2',prof )
                //     this.rowWeek[mes].semana[semana].dias[
                //       dias
                //     ].professionais.unshift({
                //       id: prof.health_professional_id,
                //       health_professional_name: prof.name,
                //       health_professional_id: prof.health_professional_id,
                //       profile: prof.profile,
                //       periodo: prof.periodo,
                //       is_extra: prof.is_extra,
                //     });
                //     break;
                //   } else {
                //     if( prof.health_professional_id === "7f483ea4-e09b-4d74-9a9c-05e9d6df465b")console.log('aqui',prof,  this.rowWeek[mes].semana[semana].dias[dias] )
                //     this.rowWeek[mes].semana[semana].dias[dias].professionais[
                //       profissional
                //     ] = {
                //       id: prof.health_professional_id,
                //       health_professional_id: prof.health_professional_id,
                //       health_professional_name: prof.name,
                //       profile: prof.profile,
                //       periodo: prof.periodo,
                //     };

                //     break;
                //   }
                // }
              }
            }
          }
        }
      }
    }
    await this.checkDaysInCalendar();
  }
  //Verifica os dias que já estão ocupados pelos profissionais
  async checkDaysInCalendar(item?: any) {
    for (var mes = 0; mes < this.rowWeek.length; mes++) {
      for (var semana = 0; semana < this.rowWeek[mes].semana.length; semana++) {
        for (
          var dias = 0;
          dias < this.rowWeek[mes].semana[semana].dias.length;
          dias++
        ) {
          for (
            var profissional: number =
              this.rowWeek[mes].semana[semana].dias[dias].professionais.length -
              1;
            profissional >= 0;
            profissional--
          ) {
            const result = this.rowWeek[mes].semana[semana].dias[
              dias
            ].professionais.filter((professional) => professional.id === "");

            if (result != null) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id != ""
              ) {
                const classElement =
                  this.rowWeek[mes].semana[semana].dias[dias].dia +
                  "-" +
                  this.rowWeek[mes].semana[semana].periodo +
                  "-" +
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id;

                setTimeout(function () {
                  var elemento = document.getElementById(classElement);

                  if (elemento) {
                    elemento.classList.add("selected");
                  }
                }, 10);
                if (!this.rowWeek[0].semana[0].diasAtivos) {
                  this.rowWeek[0].semana[0].diasAtivos = [];
                }
                this.rowWeek[0].semana[0].diasAtivos.push({
                  dia: this.rowWeek[mes].semana[semana].dias[dias].dia,
                  id_profissional:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id,
                  health_professional_name:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].name,
                  photo:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].photo,
                  crm: this.rowWeek[mes].semana[semana].dias[dias]
                    .professionais[profissional].crm,
                  periodo:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].periodo,
                  profile:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].profile,
                });
              }
            }
          }
          const hasNull = this.rowWeek[mes].semana[semana].dias[
            dias
          ].professionais.find((professionais) => professionais.id === "");
          if (item) {
            var hasProfessional = this.rowWeek[mes].semana[semana].dias[
              dias
            ].professionais.find(
              (professionais) => professionais.id === item.id
            );
          }

          if (!hasNull && !hasProfessional && item) {
            const classElement =
              this.rowWeek[mes].semana[semana].dias[dias].dia +
              "-" +
              this.rowWeek[mes].semana[semana].periodo +
              "-" +
              item.id;

            setTimeout(function () {
              var elemento = document.getElementById(classElement);

              if (elemento) {
                elemento.classList.remove("startSelect");
                elemento.classList.remove("selected");
                elemento.classList.add("isNull");
              }
            }, 10);
          }
        }
      }
    }
    this.checkPoint(this.consolidatedShift);
    //await this.checkPointNew();

    // this.consolidatedShift.map((reg) => {
    //   const has = this.externalSchedule.find((regex) => regex.id === reg.id);

    //   if (!has) {
    //     this.externalSchedule.push(reg);
    //   }
    // });

    this.externalSchedule.sort(function (a, b) {
      let oneDate = null;
      let secondDate = null;
      if (a.adjusted_start_datetime) {
        const dateFormatStart = a.adjusted_start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        oneDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      } else if (a.start_datetime && !a.adjusted_start_datetime) {
        const dateFormatStart = a.start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        oneDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      }

      if (b.adjusted_start_datetime) {
        const dateFormatStart = b.adjusted_start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        secondDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      } else if (b.start_datetime && !b.adjusted_start_datetime) {
        const dateFormatStart = b.start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        secondDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      }

      if (isAfter(oneDate, secondDate)) {
        return 1;
      }
      if (isAfter(secondDate, oneDate)) {
        return -1;
      }
      return 0;
    });
  }
  //transforma o dia em string do dia da semana
  getDia(dia) {
    if (dia == "0") {
      return "Domingo";
    } else if (dia == "1") {
      return "Segunda";
    } else if (dia == "2") {
      return "Terça";
    } else if (dia == "3") {
      return "Quarta";
    } else if (dia == "4") {
      return "Quinta";
    } else if (dia == "5") {
      return "Sexta";
    } else {
      return "Sabado";
    }
  }
  //transforma o dia em string quando for menor que 10
  formatDia(dia) {
    if (dia == 0) {
      return "";
    } else {
      if (dia < 10) {
        return "0" + dia.toString();
      } else {
        return dia;
      }
    }
  }
  //Pega o primeiro e ultimo nome do profissional
  getNameProf(name: string) {
    if (name) {
      const names = name.split(" ");
      if (names[0] === names[names.length - 1]) {
        return names[0];
      } else {
        return names[0] + " " + names[names.length - 1];
      }
    } else {
      return name;
    }
  }

  checkPoint(profSearchData: Review[], changed?: boolean) {
    var professionalToRemove = [];

    for (const professionalCheck of profSearchData) {
      const inicialStartDatetime = new Date(
        professionalCheck.adjusted_start_datetime
          ? professionalCheck.adjusted_start_datetime
          : professionalCheck.start_datetime
      ).toISOString();

      let inicialEndDatetime = professionalCheck.adjusted_end_datetime
        ? professionalCheck.adjusted_end_datetime
        : professionalCheck.end_datetime
        ? professionalCheck.end_datetime
        : null;

      const dayStart = Number(
        this.formatDate(inicialStartDatetime).split(" ")[0].split("/")[0]
      );
      let dayEnd = dayStart;

      if (inicialEndDatetime) {
        dayEnd = Number(
          this.formatDate(inicialEndDatetime).split(" ")[0].split("/")[0]
        );
      }

      if (professionalCheck.condition === "INPROGRESS") {
        this.greenProfessionals.push(professionalCheck);
      }
      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          if (
            professionalCheck.period ===
            this.rowWeek[mes].semana[semana].periodo
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              if (
                Number(this.rowWeek[mes].semana[semana].dias[dias].dia) ===
                dayStart
              ) {
                for (
                  let profissional = 0;
                  profissional <
                  this.rowWeek[mes].semana[semana].dias[dias].professionais
                    .length;
                  profissional++
                ) {
                  if (
                    !this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find(
                      (prof) =>
                        prof.id === professionalCheck.health_professional_id ||
                        prof.health_professional_id ===
                          professionalCheck.health_professional_id
                    ) &&
                    !this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find(
                      (prof) =>
                        prof.id === "" ||
                        !this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais[profissional].start_time
                    ) &&
                    changed
                  ) {
                    let prof = this.healthUnitsProfissionais.find(
                      (profissional) =>
                        profissional.health_professional_id ===
                        professionalCheck.health_professional_id
                    );

                    this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.unshift({
                      id: prof.health_professional_id,
                      health_professional_name: prof.name,
                      profile: prof.profile,
                      periodo: prof.periodo,
                      is_extra: true,
                    });
                  }

                  if (
                    changed === true
                      ? !this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais[profissional].start_time
                      : this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais[profissional].id ===
                        professionalCheck.health_professional_id
                  ) {
                    let profSearch = this.healthUnitsProfissionais.find(
                      (profissional) =>
                        profissional.health_professional_id ===
                        professionalCheck.health_professional_id
                    );

                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ] = {
                      ...professionalCheck,

                      health_professional_name: profSearch.name,
                      profile: profSearch.profile,
                      periodo: profSearch.periodo,
                      regional_council_document_number:
                        profSearch.regional_council_document_number,
                      start_time: professionalCheck.adjusted_start_datetime
                        ? getHoursMinuts(
                            professionalCheck.adjusted_start_datetime
                          )
                        : professionalCheck.start_datetime
                        ? getHoursMinuts(professionalCheck.start_datetime)
                        : null,
                      end_time: professionalCheck.adjusted_end_datetime
                        ? getHoursMinuts(
                            professionalCheck.adjusted_end_datetime
                          )
                        : professionalCheck.end_datetime
                        ? getHoursMinuts(professionalCheck.end_datetime)
                        : null,
                      total: professionalCheck.adjusted_worked_hours
                        ? professionalCheck.adjusted_worked_hours
                        : professionalCheck.worked_hours,
                      id: professionalCheck.source_id
                        ? professionalCheck.source_id
                        : professionalCheck.id,
                      health_unit_id: this.selectedUnit.id,
                      health_unit_name: this.selectedUnit.name,
                      is_extra:
                        this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais[profissional].is_extra,
                    };
                    professionalToRemove.push(professionalCheck);
                    this.externalConsolidatedShift++;

                    if (changed) {
                      professionalToRemove.map((profe) => {
                        // this.externalSchedule = this.externalSchedule.filter(
                        //   (registerFiltered) =>
                        //     (registerFiltered.source_id != null
                        //       ? registerFiltered.source_id != profe.source_id
                        //       : registerFiltered.id != profe.id)
                        // );
                        const find = this.externalSchedule.find(
                          (registerFiltered) =>
                            registerFiltered.source_id === profe.source_id &&
                            registerFiltered.id === profe.id &&
                            registerFiltered.period === profe.period
                        );
                        if (find) {
                          this.externalSchedule = this.externalSchedule.filter(
                            (registerFiltered) => registerFiltered != find
                          );
                        }
                      });
                      return;
                    }
                  } else {
                    if (
                      !this.rowWeek[mes].semana[semana].dias[
                        dias
                      ].professionais.find(
                        (prof) =>
                          prof.id ===
                            professionalCheck.health_professional_id ||
                          prof.health_professional_id ===
                            professionalCheck.health_professional_id
                      ) &&
                      !changed
                    ) {
                      if (
                        !this.externalSchedule.find(
                          (external) => external === professionalCheck
                        )
                      ) {
                        this.externalSchedule.push(professionalCheck);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async checkPointNew(profSearchData?: any) {
    var professionalToRemove = [];
    let arrayProf: any = [];
    if (profSearchData) {
      arrayProf.push(profSearchData);
    } else {
      arrayProf = this.consolidatedShift;
    }

    for (const professionalsCheck of arrayProf) {
      if (
        !professionalsCheck.adjusted_end_datetime &&
        !professionalsCheck.end_datetime
      ) {
        this.greenProfessionals.push(professionalsCheck);
      }
      //formata para que os dias virem horas
      if (professionalsCheck.worked_hours) {
        const hours = professionalsCheck.worked_hours.split(" ");
        if (hours.length > 1) {
          const horas = Number(hours[0]) * 24;
          const [hh, mm, ss] = hours[1].split(":");
          professionalsCheck.worked_hours =
            (Number(hh) + Number(horas) <= 9
              ? "0" + Number(hh) + Number(horas)
              : Number(hh) + Number(horas)) +
            ":" +
            mm +
            ":" +
            ss;
          professionalsCheck.worked_hours_copy =
            professionalsCheck.worked_hours[0] +
            professionalsCheck.worked_hours[1] +
            professionalsCheck.worked_hours[2] +
            professionalsCheck.worked_hours[3] +
            professionalsCheck.worked_hours[4];
        } else {
          professionalsCheck.worked_hours_copy =
            professionalsCheck.worked_hours[0] +
            professionalsCheck.worked_hours[1] +
            professionalsCheck.worked_hours[2] +
            professionalsCheck.worked_hours[3] +
            professionalsCheck.worked_hours[4];
        }
      }

      if (professionalsCheck.adjusted_worked_hours) {
        const hours = professionalsCheck.adjusted_worked_hours.split(" ");
        if (hours.length > 1) {
          const horas = Number(hours[0]) * 24;
          professionalsCheck.adjusted_worked_hours =
            Number(
              Number(horas) +
                Number(
                  professionalsCheck.adjusted_worked_hours
                    .split(" ")[1]
                    .split(":")[0]
                )
            ) +
            ":" +
            professionalsCheck.adjusted_worked_hours.split(":")[1] +
            ":" +
            professionalsCheck.adjusted_worked_hours.split(":")[2];
          professionalsCheck.adjusted_worked_hours_copy =
            professionalsCheck.adjusted_worked_hours[0] +
            professionalsCheck.adjusted_worked_hours[1] +
            professionalsCheck.adjusted_worked_hours[2] +
            professionalsCheck.adjusted_worked_hours[3] +
            professionalsCheck.adjusted_worked_hours[4];
        } else {
          professionalsCheck.adjusted_worked_hours_copy =
            professionalsCheck.adjusted_worked_hours[0] +
            professionalsCheck.adjusted_worked_hours[1] +
            professionalsCheck.adjusted_worked_hours[2] +
            professionalsCheck.adjusted_worked_hours[3] +
            professionalsCheck.adjusted_worked_hours[4];
        }
      }

      const inicialStartDatetime = new Date(
        professionalsCheck.adjusted_start_datetime
          ? professionalsCheck.adjusted_start_datetime
          : professionalsCheck.start_datetime
      ).toISOString();

      let inicialEndDatetime = professionalsCheck.adjusted_end_datetime
        ? professionalsCheck.adjusted_end_datetime
        : professionalsCheck.end_datetime
        ? professionalsCheck.end_datetime
        : null;

      if (inicialEndDatetime) new Date(inicialEndDatetime).toISOString();

      //Seta a cor undefined ao registro
      professionalsCheck.status = "UNDEFINED";

      //Seta a cor em progresso para o registro sem saida
      if (
        !professionalsCheck.end_datetime &&
        !professionalsCheck.adjusted_end_datetime &&
        (professionalsCheck.adjusted_start_datetime ||
          professionalsCheck.start_datetime)
      ) {
        professionalsCheck.status = "INPROGRESS";
      }
      const dayStart = Number(inicialStartDatetime.split("T")[0].split("-")[2]);
      let dayEnd = dayStart;
      if (inicialEndDatetime) {
        dayEnd = Number(inicialEndDatetime.split("T")[0].split("-")[2]);
      }
      let checked = false;
      let ignore = false;
      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        if (dayEnd < dayStart) {
          dayEnd = dayStart;
          ignore = true;
        } // caso seja o ultimo dia do mes
        for (let day = dayStart; day <= dayEnd; day++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              if (
                Number(this.rowWeek[mes].semana[semana].dias[dias].dia) === day
              ) {
                for (
                  let profissional = 0;
                  profissional <
                  this.rowWeek[mes].semana[semana].dias[dias].professionais
                    .length;
                  profissional++
                ) {
                  let isSplit = professionalsCheck.isSplit || false;

                  //data inicial do dia
                  const [startYear, startMonth, startDay] = inicialStartDatetime
                    .split("T")[0]
                    .split("-");

                  let startProfDate = new Date(
                    startYear +
                      "-" +
                      startMonth +
                      "-" +
                      startDay +
                      "T" +
                      inicialStartDatetime.split("T")[1].split(".")[0] +
                      "Z"
                  );

                  const startProxDay = addHours(
                    new Date(
                      startYear +
                        "-" +
                        startMonth +
                        "-" +
                        (day + 1 <= 9
                          ? "0" + (day + 1).toString()
                          : (day + 1).toString()) +
                        "T" +
                        this.gradeAllData.planned_workshifts[0].start_time +
                        "Z"
                    ),
                    3
                  );

                  // if (this.rowWeek[mes].semana[semana].periodo != this.gradeAllData.planned_workshifts[0].name) {
                  //   startProfDate = addHours(new Date(startYear + '-' + startMonth + '-' + (day <= 9 ? '0' + day.toString() : day.toString()) + 'T' + this.gradeAllData.planned_workshifts.find((turn) => turn.name === this.rowWeek[mes].semana[semana].periodo).start_time + 'Z'), 3);
                  // }

                  let [endYear, endMonth, endDay] = [null, null, null];
                  let endProfDate = null;

                  if (inicialEndDatetime) {
                    [endYear, endMonth, endDay] = inicialEndDatetime
                      .split("T")[0]
                      .split("-");
                    endProfDate = new Date(
                      endYear +
                        "-" +
                        endMonth +
                        "-" +
                        endDay +
                        "T" +
                        inicialEndDatetime.split("T")[1].split(".")[0]
                    );

                    //Se ja é outro dia marca que é um split
                    if (
                      day > Number(startDay) &&
                      isAfter(endProfDate, addMinutes(startProxDay, 30))
                    ) {
                      startProfDate = addHours(
                        new Date(
                          startYear +
                            "-" +
                            startMonth +
                            "-" +
                            (day <= 9 ? "0" + day.toString() : day.toString()) +
                            "T" +
                            this.gradeAllData.planned_workshifts[0].start_time +
                            "Z"
                        ),
                        3
                      );

                      isSplit = true;
                    }
                  }

                  const turn = this.gradeAllData.planned_workshifts.find(
                    (turn) =>
                      turn.name === this.rowWeek[mes].semana[semana].periodo
                  );

                  if (day > Number(startDay) && professionalsCheck.isSplit) {
                    startProfDate = addHours(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          (day <= 9 ? "0" + day.toString() : day.toString()) +
                          "T" +
                          turn.start_time +
                          "Z"
                      ),
                      3
                    );
                  }

                  let startTurnDate = addHours(
                    new Date(
                      startYear +
                        "-" +
                        startMonth +
                        "-" +
                        (day <= 9 ? "0" + day : day) +
                        "T" +
                        turn.start_time +
                        "Z"
                    ),
                    3
                  );

                  let endTurnDate = null;

                  if (
                    isLastDayOfMonth(
                      addHours(
                        new Date(
                          startYear +
                            "-" +
                            startMonth +
                            "-" +
                            (day <= 9 ? "0" + day : day)
                        ),
                        3
                      )
                    ) &&
                    Number(turn.start_time.substring(0, 2)) >
                      Number(
                        this.somartempos(
                          turn.start_time,
                          turn.duration + ":00"
                        ).substring(0, 2)
                      )
                  ) {
                    endTurnDate = addDays(
                      addHours(
                        new Date(
                          startYear +
                            "-" +
                            startMonth +
                            "-" +
                            (day <= 9 ? "0" + day : day) +
                            "T" +
                            this.somartempos(
                              turn.start_time,
                              turn.duration + ":00"
                            ) +
                            "Z"
                        ),
                        3
                      ),
                      1
                    );
                  } else {
                    endTurnDate = addHours(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          (Number(turn.start_time.substring(0, 2)) <
                          Number(
                            this.somartempos(
                              turn.start_time,
                              turn.duration + ":00"
                            ).substring(0, 2)
                          )
                            ? day <= 9
                              ? "0" + day
                              : day
                            : day + 1 <= 9
                            ? "0" + (day + 1)
                            : day + 1) +
                          "T" +
                          this.somartempos(
                            turn.start_time,
                            turn.duration + ":00"
                          ) +
                          "Z"
                      ),
                      3
                    );
                  }

                  // if(isAfter(addHours(startTurnDate, 1),new Date(startYear + '-' + startMonth + '-' + startDay + 'T' + inicialStartDatetime.split("T")[1].split(".")[0] + 'Z'))){
                  //   isSplit = true;
                  // }

                  if (
                    isBefore(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          startDay +
                          "T" +
                          inicialStartDatetime.split("T")[1].split(".")[0] +
                          "Z"
                      ),
                      subHours(endTurnDate, 3)
                    ) &&
                    !ignore &&
                    professionalsCheck.added
                  ) {
                    startProfDate = addHours(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          (day <= 9 ? "0" + day.toString() : day.toString()) +
                          "T" +
                          turn.start_time +
                          "Z"
                      ),
                      3
                    );
                  }

                  if (
                    !isBefore(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          startDay +
                          "T" +
                          inicialStartDatetime.split("T")[1].split(".")[0] +
                          "Z"
                      ),
                      subHours(endTurnDate, 1)
                    ) &&
                    !ignore &&
                    isAfter(endProfDate, addMinutes(startProxDay, 30))
                  ) {
                    startProfDate = addHours(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          (day <= 9 ? "0" + day.toString() : day.toString()) +
                          "T" +
                          turn.start_time +
                          "Z"
                      ),
                      3
                    );
                  }

                  if (checked && isAfter(endProfDate, startProxDay)) {
                    startProfDate = addHours(
                      new Date(
                        startYear +
                          "-" +
                          startMonth +
                          "-" +
                          (day <= 9 ? "0" + day : day) +
                          "T" +
                          turn.start_time +
                          "Z"
                      ),
                      3
                    );
                  }

                  startTurnDate = subMinutes(startTurnDate, 15);
                  endTurnDate = addMinutes(endTurnDate, 15);

                  //ignora caso o tempo entre as datas for menor que uma hora
                  //editado aqui

                  if (isAfter(addHours(startProfDate, 3), endProfDate))
                    continue;

                  //editado aqui
                  if (
                    isAfter(endTurnDate, startProfDate) &&
                    isAfter(endTurnDate, addHours(startProfDate, 3))
                  ) {
                    let start = subHours(startProfDate, 3)
                      .toISOString()
                      .split("T")[1]
                      .split(".")[0];

                    if (
                      professionalsCheck.type === "register" &&
                      isSplit &&
                      turn.name != this.gradeAllData.planned_workshifts[0].name
                    ) {
                      start = addMinutes(subHours(startTurnDate, 3), 15)
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0];
                    }

                    let end = null;
                    //verifica se tem proximo turno
                    if (endProfDate) {
                      end = subHours(endProfDate, 3)
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0];
                      //editado aqui

                      //mudar aqui a trava de split
                      if (
                        professionalsCheck.id ===
                        "2a29e0aa-1dd0-4382-ad3b-a78b8501b492"
                      )
                        if (isAfter(endProfDate, addHours(endTurnDate, 4))) {
                          end = this.somartempos(
                            turn.start_time,
                            turn.duration + ":00"
                          );

                          endProfDate = addHours(
                            new Date(
                              endYear +
                                "-" +
                                endMonth +
                                "-" +
                                (Number(turn.start_time.substring(0, 2)) <
                                Number(
                                  this.somartempos(
                                    turn.start_time,
                                    turn.duration + ":00"
                                  ).substring(0, 2)
                                )
                                  ? day <= 9
                                    ? "0" + day
                                    : day
                                  : day + 1 <= 9
                                  ? "0" + (day + 1)
                                  : day + 1) +
                                "T" +
                                end +
                                "Z"
                            ),
                            3
                          );
                          isSplit = true;

                          if (!isNaN(endProfDate.getTime())) {
                          } else {
                            endProfDate = addHours(
                              new Date(
                                endYear +
                                  "-" +
                                  endMonth +
                                  "-" +
                                  (day <= 9 ? "0" + day : day) +
                                  "T" +
                                  end +
                                  "Z"
                              ),
                              3
                            );
                          }

                          // if(professionalsCheck.added){
                          //   startProfDate = addHours(new Date(startYear + '-' + startMonth + '-' + (day <= 9 ? '0' + day.toString() : day.toString()) + 'T' + turn.start_time + 'Z'), 3);
                          // }
                        }

                      if (isAfter(startTurnDate, subMinutes(endProfDate, 30))) {
                        continue;
                      }
                    }

                    let scheduled = false;

                    scheduled = await this.checkInSchedule(professionalsCheck);

                    //Adiciona registro
                    if (profSearchData) {
                      const register = this.rowWeek[mes].semana[semana].dias[
                        dias
                      ].professionais.find(
                        (prof) =>
                          prof?.status === "COLORMISSING" || !prof?.status
                      );

                      if (!register) {
                        let prof = this.healthUnitsProfissionais.find(
                          (profissional) =>
                            profissional.health_professional_id ===
                            professionalsCheck.health_professional_id
                        );

                        this.rowWeek[mes].semana[semana].dias[
                          dias
                        ].professionais.unshift({
                          id: prof.health_professional_id,
                          health_professional_name: prof.name,
                          profile: prof.profile,
                          periodo: prof.periodo,
                          is_extra: true,
                        });
                      }
                    }
                    if (
                      !profSearchData
                        ? this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.name ===
                          professionalsCheck?.health_professional_name
                        : this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.name ===
                            professionalsCheck?.health_professional_name ||
                          this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.name === null ||
                          this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.name === "" ||
                          this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.id === "" ||
                          this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.status ===
                            "NOREPORT" ||
                          !this.rowWeek[mes].semana[semana].dias[dias]
                            .professionais[profissional]?.status
                    ) {
                      if (
                        !profSearchData
                          ? professionalsCheck.status &&
                            professionalsCheck.status != "INPROGRESS" &&
                            professionalsCheck.status != "NOREPORT"
                          : true
                      ) {
                        professionalsCheck.status = this.checkStatusHors(
                          this.rowWeek[mes].semana[semana].periodo,
                          start,
                          end
                        );
                      }
                      //Evita que encaixe duas vezes caso não  split
                      if (
                        !professionalsCheck.isSplit &&
                        professionalsCheck.added
                      )
                        continue;

                      if (
                        professionalsCheck.id ===
                        "2a29e0aa-1dd0-4382-ad3b-a78b8501b492"
                      )
                        if (
                          start &&
                          end &&
                          Number(
                            this.calculaDuracao(start, end).substring(0, 2)
                          ) < 4
                        )
                          //mudar aqui a trava de split
                          continue;

                      this.rowWeek[mes].semana[semana].dias[dias].professionais[
                        profissional
                      ] = {
                        ...this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais[profissional],
                        ...this.healthUnitsProfissionais.find(
                          (profissional) =>
                            profissional.name ===
                            professionalsCheck.health_professional_name
                        ),
                        health_professional_name:
                          professionalsCheck.health_professional_name,
                        start_datetime: start ? start : null,
                        end_datetime: end ? end : null,
                        total: this.calculaDuracao(start, end),
                        status: professionalsCheck.status,
                        id: professionalsCheck.health_professional_id,
                        health_professional_id:
                          professionalsCheck.health_professional_id,
                        start_id: professionalsCheck.health_professional_id,
                        professional_check_id: professionalsCheck.id,
                        register: {
                          ...professionalsCheck,
                          startProfDate: startProfDate ? startProfDate : null,
                          endProfDate: endProfDate ? endProfDate : null,
                          start: start ? start : null,
                          end: end ? end : null,
                          isSplit: professionalsCheck?.isSplit || isSplit,
                          period: turn.name,
                          inicialStartDatetime: inicialStartDatetime
                            ? inicialStartDatetime
                            : null,
                          inicialEndDatetime: inicialEndDatetime
                            ? inicialEndDatetime
                            : null,
                        },
                        period: turn.name,
                        isSplit: professionalsCheck?.isSplit || isSplit,
                      };
                      professionalsCheck.added = true;
                      professionalsCheck.isSplit = isSplit;
                      profissional =
                        this.rowWeek[mes].semana[semana].dias[dias]
                          .professionais.length;
                      professionalToRemove.push(professionalsCheck);
                      this.externalConsolidatedShift++;
                      checked = true;
                      break;
                      continue;
                    } else if (
                      isSplit && !scheduled
                        ? true
                        : profSearchData
                        ? profSearchData
                        : true
                    ) {
                      //se ta escalado pula
                      const checkin = this.rowWeek[mes].semana[semana].dias[
                        dias
                      ].professionais.find(
                        (prof) =>
                          prof.id ===
                            professionalsCheck.health_professional_id ||
                          prof.health_professional_id ===
                            professionalsCheck.health_professional_id
                      );
                      if (checkin) continue;

                      if (!startProfDate || !endProfDate) continue;

                      if (
                        isBefore(startProfDate, new Date(inicialStartDatetime))
                      )
                        continue;

                      //mudar aqui a trava de split
                      if (
                        Number(
                          this.calculaDuracao(start, end).substring(0, 2)
                        ) <= 4
                      )
                        continue;

                      ////aqui voce precisa ver porque nao tem periodo
                      if (this.rowWeek[mes].semana[semana].periodo != turn.name)
                        continue;
                      const data = {
                        health_professional_name:
                          this.healthUnitsProfissionais.find(
                            (profissional) =>
                              profissional?.name ===
                              professionalsCheck?.health_professional_name
                          )?.name,
                        health_professional_id:
                          professionalsCheck.health_professional_id,
                        adjusted_start_datetime: this.formatDate(
                          startProfDate.toISOString()
                        ),
                        adjusted_end_datetime: endProfDate
                          ? this.formatDate(endProfDate?.toISOString())
                          : null,
                        adjusted_worked_hours: this.calculaDuracao(start, end),
                        start_datetime: this.formatDate(inicialStartDatetime),
                        end_datetime: this.formatDate(inicialEndDatetime),
                        worked_hours:
                          professionalsCheck.adjusted_worked_hours ||
                          professionalsCheck.worked_hours,
                        period: this.rowWeek[mes].semana[semana].periodo,
                        type: "split",
                        id: professionalsCheck.id,
                        registro:
                          professionalsCheck?.isSplit || isSplit
                            ? "Split Automático"
                            : null,
                        isSplit: professionalsCheck?.isSplit || isSplit,
                      };
                      professionalsCheck.isSplit =
                        professionalsCheck?.isSplit || isSplit;
                      professionalsCheck.type = "register";

                      if (
                        !this.externalSchedule.find(
                          (reg) =>
                            reg.adjusted_start_datetime ===
                              data.adjusted_start_datetime &&
                            reg.adjusted_end_datetime ===
                              data.adjusted_end_datetime &&
                            this.rowWeek[mes].semana[semana].periodo ===
                              data?.period
                        )
                      ) {
                        this.externalSchedule.push(data as any);
                      }
                      checked = true;

                      // break;

                      // profissional = this.rowWeek[mes].semana[semana].dias[dias].professionais.length;
                    } else if (!isSplit) {
                      // professionalsCheck.period = this.rowWeek[mes].semana[semana].period;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    professionalToRemove.map((profe) => {
      this.consolidatedShift = this.consolidatedShift.filter(
        (registerFiltered) => registerFiltered.id != profe.id
      );
    });

    if (profSearchData) {
      const indexRemove = this.externalSchedule.findIndex(
        (registerFiltered) =>
          registerFiltered.id === profSearchData.id &&
          registerFiltered.adjusted_start_datetime ===
            this.formatDate(
              new Date(profSearchData.adjusted_start_datetime).toISOString()
            ) &&
          registerFiltered.adjusted_end_datetime ===
            this.formatDate(
              new Date(profSearchData.adjusted_end_datetime)?.toISOString()
            ) &&
          registerFiltered.period === profSearchData.period
      );

      this.externalSchedule.splice(indexRemove, 1);
    }

    for (const professionalsCheck of this.consolidatedShift) {
      if (!professionalsCheck.formated) {
        professionalsCheck.formated = true;
        if (!professionalsCheck?.isSplit) {
          if (professionalsCheck.adjusted_start_datetime)
            professionalsCheck.adjusted_start_datetime = this.formatDate(
              professionalsCheck.adjusted_start_datetime
            );
          if (professionalsCheck.adjusted_end_datetime)
            professionalsCheck.adjusted_end_datetime = this.formatDate(
              professionalsCheck.adjusted_end_datetime
            );
          if (professionalsCheck.start_datetime)
            professionalsCheck.start_datetime = this.formatDate(
              professionalsCheck.start_datetime
            );
          if (professionalsCheck.end_datetime)
            professionalsCheck.end_datetime = this.formatDate(
              professionalsCheck.end_datetime
            );
        }
      }
    }

    await this.checkGreenDays();

    this.loading = false;
  }
  checkInSchedule(professionalsCheck: any) {
    if (
      professionalsCheck.adjusted_end_datetime ||
      professionalsCheck.end_datetime
    ) {
      const start_day = Number(
        (professionalsCheck.adjusted_start_datetime
          ? professionalsCheck.adjusted_start_datetime
          : professionalsCheck.start_datetime
        ).substring(8, 10)
      );
      const end_day = Number(
        (professionalsCheck.adjusted_end_datetime
          ? professionalsCheck.adjusted_end_datetime
          : professionalsCheck.end_datetime
        ).substring(8, 10)
      );

      for (let date = start_day; start_day <= end_day; date++) {
        for (var mes = 0; mes < this.rowWeek.length; mes++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              if (
                Number(this.rowWeek[mes].semana[semana].dias[dias].dia) ===
                Number(date)
              ) {
                for (
                  var profissional: number =
                    this.rowWeek[mes].semana[semana].dias[dias].professionais
                      .length - 1;
                  profissional >= 0;
                  profissional--
                ) {
                  if (
                    !this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find(
                      (profis) => profis.id != null && profis.id != ""
                    )
                  )
                    return false;
                  if (
                    this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find(
                      (profis) => profis.id === null || profis.id === ""
                    )
                  )
                    return true;
                  if (
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id === professionalsCheck.health_professional_id
                  ) {
                    return true;
                  }
                }
                return false;
              }
            }
          }
        }
      }
    } else {
      return true;
    }
  }

  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds

    if (hours > 24) {
      hours = hours - 24;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes > 60) {
      minutes = minutes - 60;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    return hours + ":" + minutes; // Return is HH : MM
  }
  removeRegister(registro: ConsolidatedShift) {
    this.openDialogDisable("Deseja desativar o registro?", registro);
  }
  openDialogDisable(texto, registro): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == "0") {
        const data = { work_shift_id: registro.id, status: true };
        await this.reviewService.removeReviewProfessional(data).subscribe(
          () => {
            const indexRemove = this.consolidatedShift.findIndex(
              (registerFiltered) =>
                registerFiltered.health_professional_name ===
                  registro.health_professional_name &&
                registerFiltered.health_unit_name ===
                  registro.health_unit_name &&
                registerFiltered.adjusted_worked_hours ===
                  registro.adjusted_worked_hours &&
                registerFiltered.worked_hours === registro.worked_hours
            );

            this.consolidatedShift.splice(indexRemove, 1);
          },
          () => {
            this.openDialog("Erro ao remover o Profissional!");
          }
        );
      }
    });
  }

  checkStatus(prof, dia, periodo) {
    const startTimePeriod = periodo.periodoLabel.split("-")[0];

    const turnDate = new Date(
      this.yearSchedule +
        "-" +
        this.realMonth +
        "-" +
        (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
        "T" +
        startTimePeriod
    );

    let [horas, minutos, segundos] = periodo.duration.split(":").map(Number);
    const endTurnDate = new Date(
      this.yearSchedule +
        "-" +
        this.realMonth +
        "-" +
        (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
        "T" +
        startTimePeriod
    );
    // Adicionando horas, minutos e segundos à data
    endTurnDate.setHours(endTurnDate.getHours() + horas);
    endTurnDate.setMinutes(endTurnDate.getMinutes() + minutos);
    endTurnDate.setSeconds(endTurnDate.getSeconds() + segundos);

    const hasGreenProfessional = this.greenProfessionals.find(
      (professional) =>
        professional.health_professional_name ===
          prof.health_professional_name &&
        isAfter(
          new Date(),
          subHours(
            new Date(
              professional.adjusted_start_datetime
                ? professional.adjusted_start_datetime
                : professional.start_datetime
            ),
            3
          )
        )
    );

    const profDate =
      prof.adjusted_start_datetime || prof.start_datetime
        ? new Date(
            this.formatDate(prof.adjusted_start_datetime ?? prof.start_datetime)
          )
        : null;
    if (prof.condition === "SUCCESS") {
      return "color-success";
    } else if (prof.condition === "OUTSIDE") {
      return "color-outside";
    } else if (prof.condition === "INPROGRESS") {
      return "color-inProgress";
    } else if (
      isAfter(new Date(), endTurnDate) &&
      !prof.condition &&
      !hasGreenProfessional
    ) {
      return "color-missing";
    } else if (
      isAfter(new Date(), turnDate) &&
      isAfter(endTurnDate, new Date()) &&
      !profDate &&
      !prof.condition
    ) {
      return "color-missing-day";
    } else if (
      isAfter(new Date(), turnDate) &&
      isAfter(turnDate, subMinutes(profDate, 60)) &&
      !prof.condition &&
      hasGreenProfessional
    ) {
      return "color-inProgress";
    } else if (prof.end === null) {
      return "color-noReport";
    }

    // return "color-missing";

    // const startDatePeriod = new Date(
    //   this.yearSchedule +
    //     "-" +
    //     this.realMonth +
    //     "-" +
    //     (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
    //     "T" +
    //     periodo.realStartDate
    // );

    // let endDatePeriod = new Date(
    //   this.yearSchedule +
    //     "-" +
    //     this.realMonth +
    //     "-" +
    //     (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
    //     "T" +
    //     this.somartempos(periodo.realStartDate, periodo.duration)
    // );

    // if (
    //   Number(
    //     this.somartempos(periodo.realStartDate, periodo.duration).substring(
    //       0,
    //       1
    //     )
    //   ) < Number(periodo.realStartDate.substring(0, 1))
    // ) {
    //   endDatePeriod = addDays(endDatePeriod, 1);
    // }

    // if (
    //   prof.condition === "INPROGRESS" &&
    //   (Number(dia) < Number(dd) ||
    //     Number(this.realMonth) < Number(mm) ||
    //     Number(this.yearSchedule) < Number(yyyy)) &&
    //   (prof.end_datetime || prof.adjusted_end_datetime)
    // ) {
    //   return "color-missing";
    // }

    // if (
    //   isAfter(new Date(), startDatePeriod) &&
    //   isAfter(endDatePeriod, new Date()) &&
    //   !prof.register
    // ) {
    //   return "color-missing-day";
    // }

    // if (prof.condition === "SUCCESS") {
    //   //   const isGestor = prof.register.adjusted_start_datetime_original ? true : false;
    //   if (prof.register && prof.register.adjusted_start_datetime_original) {
    //     return "color-success-gestor";
    //   } else {
    //     return "color-success";
    //   }
    // }
    // if (prof.condition === "OUTSIDE") {
    //   if (prof.register && prof.register.adjusted_start_datetime_original) {
    //     return "color-success-gestor";
    //   }
    // }

    // if (prof.condition === "OUTSIDE") {
    //   return "color-outside";
    // }
    // if (prof.condition === "INPROGRESS" && isAfter(new Date(), startDatePeriod)) {
    //   return "color-inProgress";
    // } else if (prof.status === "INPROGRESS") {
    //   return "color-noReport";
    // }
    // if (
    //   !prof.start_datetime &&
    //   !prof.end_datetime &&
    //   dia === dd &&
    //   Number(this.realMonth) === Number(mm) &&
    //   Number(this.yearSchedule) < Number(yyyy)
    // )
    //   return "color-missing-day";
    // if (
    //   prof.status === "NOREPORT" &&
    //   (Number(dia) < Number(dd) ||
    //     Number(this.realMonth) < Number(mm) ||
    //     Number(this.yearSchedule) < Number(yyyy))
    // )
    //   return "color-missing";
    // if (prof.status === "NOREPORT") return "color-noReport";
    // if (
    //   !prof.status &&
    //   prof.name &&
    //   (Number(dia) < Number(dd) ||
    //     Number(this.realMonth) < Number(mm) ||
    //     Number(this.yearSchedule) < Number(yyyy))
    // ) {
    //   return "color-missing";
    // }
    // if (!prof.condition && prof.name) return "color-noReport";
    // return "color-missing";
  }
  checkStatusHors(periodo: string, startProf: string, endProf: string) {
    let status = "";

    const [day, month, year] = this.getActualDate();

    const period = this.gradeAllData.planned_workshifts.find(
      (turn) => turn.name === periodo
    );
    if (period) {
      const startDateProf = new Date(
        year + "-" + month + "-" + day + "T" + startProf
      );
      let endDateProf = new Date(
        year + "-" + month + "-" + day + "T" + endProf
      );

      let startDatePeriod = new Date(
        year + "-" + month + "-" + day + "T" + period.start_time
      );
      let endDatePeriod = new Date(
        year +
          "-" +
          month +
          "-" +
          day +
          "T" +
          this.somartempos(
            period.start_time,
            period.duration + ":00"
          ).substring(0, 5)
      );

      let otherDay = false;
      if (
        Number(
          this.somartempos(
            period.start_time,
            period.duration + ":00"
          ).substring(0, 1)
        ) < Number(period.start_time.substring(0, 1))
      )
        otherDay = true;

      const startFinish = addMinutes(startDatePeriod, 15);
      let endFinish = addMinutes(endDatePeriod, 15);
      startDatePeriod = subMinutes(startDatePeriod, 15);
      endDatePeriod = subMinutes(endDatePeriod, 15);
      if (otherDay) {
        endDatePeriod = addDays(endDatePeriod, 1);
        endFinish = addDays(endFinish, 1);
        endDateProf = addDays(endDateProf, 1);
      }

      if (
        isAfter(startDateProf, startDatePeriod) &&
        isAfter(startFinish, startDateProf)
      ) {
        if (
          isAfter(endDateProf, endDatePeriod) &&
          isAfter(endFinish, endDateProf)
        ) {
          status = "SUCCESS";
        } else {
          status = "OUTSIDE";
        }
      } else {
        status = "OUTSIDE";
      }
    }
    return status;
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getActualDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: string = (today.getMonth() + 1).toString(); // Months start at 0!
    let dd: string = today.getDate().toString();

    if (Number(dd) < 10) dd = "0" + dd;
    if (Number(mm) < 10) mm = "0" + mm;
    return [dd, mm, yyyy];
  }

  setProfissional() {}

  openDialogRegistro(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana,
    prof_id
  ) {
    const start_time = new Date(
      Number(this.yearSchedule),
      Number(this.realMonth) - 1,
      Number(dia)
    );
    const end_time = new Date(
      Number(this.yearSchedule),
      Number(this.realMonth) - 1,
      Number(dia)
    );

    const dialogRef = this.dialog.open(DetailRegistroComponent, {
      width: "1000px",
      height: "200px",
      panelClass: "azulPanel",
      disableClose: true,
      data: {
        description: {},
        unit: this.selectedUnit.id,
        grid: this.selectedGrade.id,
        period: dayGrid.periodo,
        day: dia,
        start_time: start_time,
        end_time: end_time,
        prof_id: prof_id,
        occupation_area_id: this.selectedGrade.occupation_area,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRefs = this.dialog.open(DialogComponent, {
          width: "570px",
          height: "170px",
          panelClass: "vermelhoPanel",
          data: {
            description: "Tem certeza que deseja adicionar esse registro?",
            enableCancel: true,
          },
        });

        dialogRefs.afterClosed().subscribe((results) => {
          if (results == "0") {
            /*abrir modal*/
            this.cols = [
              { field: "doctor", header: "Profissionais de saúde" },
              { field: "clinic", header: "Unidade" },
              { field: "entry", header: "Entrada" },
              { field: "manualentry", header: "Entrada Ajustada" },
              { field: "leave", header: "Saída" },
              { field: "manualeave", header: "Saída Ajustada" },
              { field: "hours", header: "Tempo" },
              { field: "manualhours", header: "Tempo Ajustado" },
              { field: "adjustedat", header: "Ajustado em" },
              { field: "action", header: "" },
            ];
            this.showTable = true;

            this.consolidatedShiftService
              .createConsolidedShift(result)
              .subscribe(
                (data) => {
                  if (data.id != "") {
                    var entradaManual =
                      new Date(result.modelDataInicio).toLocaleDateString() +
                      " - " +
                      new Date(result.modelDataInicio).toLocaleTimeString();
                    var saidaManual =
                      new Date(result.modelDataFim).toLocaleDateString() +
                      " - " +
                      new Date(result.modelDataFim).toLocaleTimeString();

                    let pro = {
                      id: data.id,
                      image: result.image,
                      doctor: result.doctor,
                      clinic: result.clinic,
                      health_professional_id: data.health_professional,
                      entry: "__ /__ /____ __ : __",
                      manualentryDate: result.modelDataInicio,
                      manualentry: entradaManual,
                      manualeaveDate: result.modelDataFim,
                      leave: "__ /__ /____ __ : __",
                      manualeave: saidaManual,
                      hours: "0",
                      manualhours: data.adjusted_worked_hours,
                      adjustedat: "__ /__ /____ __ : __",
                      action: false,
                    };
                  }

                  this.addProfessional(
                    dayGrid,
                    dia,
                    positionDia,
                    positionBlock,
                    positionPeriodo,
                    semana,
                    data.health_professional,
                    prof_id
                  );
                },
                (error) => {
                  this.openDialog("Não foi possível realizar o registro!");
                  this.loading = false;
                  return;
                }
              );
          } else {
          }
        });
      }
    });
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  addProfessional(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana,
    health_professional_id,
    last_prof_id
  ) {
    var unique = false;

    if (dia != "") {
      unique = true;
    }

    let profSearch = this.healthUnitsProfissionais.find(
      (profissional) =>
        profissional.health_professional_id === health_professional_id
    );

    const result: any[] = [];
    result[0] = {
      ...profSearch,
      dia: dia,
      id: health_professional_id,
      profissional: profSearch,
    };
    result[0].profissional.id = profSearch.health_professional_id;

    for (var sem = 0; sem < this.rowWeek.length; sem++) {
      for (
        var dias = 0;
        dias < this.rowWeek[sem].semana[positionPeriodo].dias.length;
        dias++
      ) {
        if (this.rowWeek[sem].semana[positionPeriodo].dias[dias].dia != 0) {
          var filter = result.find(
            (element) =>
              element.dia ==
              this.rowWeek[sem].semana[positionPeriodo].dias[dias].dia
          );

          if (filter) {
            let filterIndex =
              this.rowWeek[sem].semana[positionPeriodo].dias[
                dias
              ].professionais.findIndex(
                (element) =>
                  element.id === "" && !element.health_professional_id
              ) ||
              this.rowWeek[sem].semana[positionPeriodo].dias[
                dias
              ].professionais.findIndex(
                (element) =>
                  element.id === last_prof_id &&
                  element.health_professional_id != ""
              );

            if (filterIndex === -1) filterIndex = 0;

            this.rowWeek[sem].semana[positionPeriodo].dias[dias].professionais[
              filterIndex
            ] = result[0]["profissional"];
          }
        }
      }
    }
    this.loading = false;
    this.publicar();
  }

  openEditDIalog(valor) {
    let periods = [];

    this.rowWeek[0].semana.map((turno) => {
      periods.push(turno.periodo);
    });

    const body = {
      doctor: valor.health_professional_name,
      health_professional_id: valor.health_professional_id,
      entry: valor.start_datetime,
      leave: valor.end_datetime,
      hours: valor.worked_hours,
      clinic: valor.health_unit_name,
      manualentryDate: valor.adjusted_start_datetime,
      manualeaveDate: valor.adjusted_end_datetime,
      manualhours: valor.adjusted_worked_hours,
      id: valor.id,
      periods: periods,
      escalas: this.gradeData.results,
    };
    const dialogRef = this.dialog.open(EditRegistroComponent, {
      width: "1090px",
      height: "347px",
      panelClass: "azulPanel",
      data: {
        description: body,
        selectUnity: this.selectedUnit.id,
        acompanhamento: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        valor = result;
      }
      // this.requestData();
    });
  }

  async publicar(publish?: boolean) {
    var arrayToPost = {
      schedule: this.schedule.id,
      work_slots: [],
      // worked_shifts_id: [],
      health_team: [],
      published: publish || false,
      review: true,
    };

    var arraySplit = [];

    for (var w = 0; w < this.rowWeek.length; w++) {
      for (var sem = 0; sem < this.rowWeek[w].semana.length; sem++) {
        for (
          var dia = 0;
          dia < this.rowWeek[w].semana[sem].dias.length;
          dia++
        ) {
          if (this.rowWeek[w].semana[sem].dias[dia].dia != 0) {
            var day;
            var dateBd = this.rowWeek[w].semana[sem].realStartDate;
            if (this.rowWeek[w].semana[sem].dias[dia].dia < 10) {
              day = "0" + this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            } else {
              day = this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            }

            var today = new Date(
              this.yearSchedule +
                "-" +
                this.realMonth +
                "-" +
                day +
                "T" +
                dateBd +
                "Z"
            );
            var splitHora = dateBd.split(":");
            var starts = this.rowWeek[w].semana[sem].duration.split(":");
            var hh;
            if (splitHora[0] < 10) {
              hh = Number(splitHora[0].toString());
            } else {
              hh = splitHora[0];
            }
            today.setHours(hh, 0, 0);
            var jj;
            if (starts[0] < 10) {
              jj = Number(starts[0].toString());
            } else {
              jj = starts[0];
            }

            today.setHours(Number(today.getHours()) + Number(jj));
            var endDay = today.toLocaleTimeString();

            for (
              var prof = 0;
              prof < this.rowWeek[w].semana[sem].dias[dia].professionais.length;
              prof++
            ) {
              if (
                this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                  .health_professional_name != "" &&
                this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                  .health_professional_name
              ) {
                arrayToPost.work_slots.push({
                  health_professional:
                    this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                      .health_professional_id,
                  replaced: null,
                  period: this.rowWeek[w].semana[sem].periodo,
                  start_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + day, //+"T"+dateBd+"Z",
                  finalization_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + day, //+"T"+endDay+"Z",
                  planned_workshift: this.rowWeek[w].semana[sem].id,
                  is_extra:
                    this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                      .is_extra || false,
                });
                // if (this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                //   .professional_check_id) {
                //   arrayToPost.worked_shifts_id.push(this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                //     .professional_check_id);
                // }
              }
            }
          }
        }
      }
    }

    this.loading = true;
    await this.workSlotService.sendWorkSlot(arrayToPost).subscribe(
      (data) => {
        this.loading = false;
        this.openDialogSucessoSave(
          publish
            ? "Escala publicada com sucesso!"
            : "Escala salva com sucesso!"
        );
        // this.requestData();
      },

      (error) => {
        this.loading = false;
        this.openDialog("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }

  openDialogSucessoSave(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  changeProf(prof) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: {
        description: "Deseja adicionar esse registro na escala?",
        enableCancel: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        // const data = {
        //   ...prof,
        //   health_professional_name: prof.health_professional_name,
        //   adjusted_start_datetime: null,
        //   adjusted_end_datetime: null,
        //   adjusted_worked_hours: prof.adjusted_worked_hours,
        //   start_datetime: null,
        //   end_datetime: null,
        //   worked_hours: prof.worked_hours,
        //   period: prof.period,

        //   id: prof.id,
        // };

        // if (prof.adjusted_start_datetime) {
        //   const adjusted_start_datetime =
        //     prof.adjusted_start_datetime.split(" ");
        //   const [dd, mm, yyyy] = adjusted_start_datetime[0].split("/");
        //   data.adjusted_start_datetime =
        //     yyyy + "-" + mm + "-" + dd + "T" + adjusted_start_datetime[1];
        // }
        // if (prof.adjusted_end_datetime) {
        //   const adjusted_end_datetime = prof.adjusted_end_datetime.split(" ");
        //   const [dd, mm, yyyy] = adjusted_end_datetime[0].split("/");
        //   data.adjusted_end_datetime =
        //     yyyy + "-" + mm + "-" + dd + "T" + adjusted_end_datetime[1];
        // }
        // if (prof.start_datetime) {
        //   const start_datetime = prof.start_datetime.split(" ");
        //   const [dd, mm, yyyy] = start_datetime[0].split("/");
        //   data.start_datetime =
        //     yyyy + "-" + mm + "-" + dd + "T" + start_datetime[1];
        // }
        // if (prof.end_datetime) {
        //   const end_datetime = prof.end_datetime.split(" ");
        //   const [dd, mm, yyyy] = end_datetime[0].split("/");
        //   data.end_datetime =
        //     yyyy + "-" + mm + "-" + dd + "T" + end_datetime[1];
        // }

        this.checkPoint([prof], true);

        return;
      }
    });
  }
  openEditDialog(register, prof_id) {
    let entryManual: string = "";
    let entryManualDate: Date = null;
    let leaveManual: string = "";
    let leaveManualDate: Date = null;
    let allHours: string = "";
    let manualAllHours: string = "";
    let entry: string = "__ /__ /____ __ : __";
    let leave: string = "__ /__ /____ __ : __";
    if (register.adjusted_start_datetime) {
      entryManual =
        // DD/MM/YYYY HH:MM:SS
        this.formatDate(register.adjusted_start_datetime).split(" ")[0] +
        " - " +
        this.formatDate(register.adjusted_start_datetime).split(" ")[1];
      entryManualDate = new Date(
        this.formatDate(register.adjusted_start_datetime)
      );
    }

    if (register.adjusted_end_datetime) {
      leaveManual =
        this.formatDate(register.adjusted_end_datetime).split(" ")[0] +
        " - " +
        this.formatDate(register.adjusted_end_datetime).split(" ")[1];
      leaveManualDate = new Date(
        this.formatDate(register.adjusted_end_datetime)
      );
    }

    if (register.worked_hours) {
      allHours = this.formatHour(register.worked_hours);
    }

    if (register.adjusted_worked_hours) {
      manualAllHours = this.formatHour(register.adjusted_worked_hours);
    }

    if (register.start_datetime) {
      entry =
        this.formatDate(register.start_datetime).split(" ")[0] +
        " - " +
        this.formatDate(register.start_datetime).split(" ")[1];
    }

    if (register.end_datetime) {
      leave =
        this.formatDate(register.end_datetime).split(" ")[0] +
        " - " +
        this.formatDate(register.end_datetime).split(" ")[1];
    }

    let pro = {
      id: register.professional_check_id || register.id,
      image: register.health_professional_photo,
      doctor: register.health_professional_name,
      clinic: register.health_unit_name,
      health_professional_id: register.health_professional_id,

      entry: entry,
      manualentry: entryManual,
      manualentryDate: entryManualDate,
      leave: leave,
      manualeave: leaveManual,
      manualeaveDate: leaveManualDate,
      hours: allHours,
      manualhours: manualAllHours,
    };

    const dialogRef = this.dialog.open(EditRegistroComponent, {
      width: "1090px",
      height: "347px",
      panelClass: "azulPanel",
      data: {
        noShow: true,
        description: { ...pro, clinic: register.health_unit_name },
        selectUnity: register.health_unit_id,
        prof_id: register.health_professional_id,
        occupation_area_id: this.selectedGrade.occupation_area,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: "570px",
          height: "170px",
          panelClass: "vermelhoPanel",
          data: {
            description: "Deseja salvar as alterações?",
            enableCancel: true,
          },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result == "0") {
            await this.publicar(false);
          }
          await this.requestData();
        });
      }
    });
  }

  editProf(
    prof,
    dayGrid?,
    dia?,
    positionDia?,
    positionBlock?,
    positionPeriodo?,
    semana?
  ) {
    console.log(dayGrid, "222dayGrid");
    if (prof.id || prof.source_id) {
      this.openEditDialog(
        {
          ...prof,
          adjusted_start_datetime: prof.is_split
            ? prof.start_datetime
            : prof.adjusted_start_datetime,
          adjusted_end_datetime: prof.is_split
            ? prof.end_datetime
            : prof.adjusted_end_datetime,
        },
        prof.id ?? prof.source_id
      );
    } else {
      this.openDialogRegistro(
        dayGrid,
        dia,
        positionDia,
        positionBlock,
        positionPeriodo,
        semana,
        prof.id
      );
    }
  }
  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  formatDateAntiga(data: string) {
    const date = data.split(" ");
    const [dia, mes, ano] = date[0].split("/");
  }

  changeGrid() {
    this.clearTable();
    this.getProfissionais(
      this.selectedGrade.occupation_area,
      this.selectedGrade.areas_of_expertise_id
    );
  }
  somartempos(tempo1, tempo2) {
    if (tempo1 && tempo2) {
      var array1 = tempo1.split(":");

      var tempo_seg1: any =
        parseInt(array1[0]) * 3600 +
        parseInt(array1[1]) * 60 +
        parseInt(array1[2]);

      var array2 = tempo2.split(":");

      var tempo_seg2: any =
        parseInt(array2[0]) * 3600 +
        parseInt(array2[1]) * 60 +
        parseInt(array2[2]);

      var tempofinal = parseInt(tempo_seg1) + parseInt(tempo_seg2);

      var hours = Math.floor(tempofinal / (60 * 60));

      if (hours > 24) hours = hours - 24;

      var divisorMinutos = tempofinal % (60 * 60);

      var minutes = Math.floor(divisorMinutos / 60);
      if (minutes > 60) {
        minutes = minutes - 60;
        hours = hours + 1;
      }

      var divisorSeconds = divisorMinutos % 60;

      var seconds = Math.ceil(divisorSeconds);

      var contador = "";

      if (hours < 10) {
        contador = "0" + hours + ":";
      } else {
        contador = hours + ":";
      }

      if (minutes < 10) {
        contador += "0" + minutes + ":";
      } else {
        contador += minutes + ":";
      }

      if (seconds < 10) {
        contador += "0" + seconds;
      } else {
        contador += seconds;
      }

      return contador;
    }
  }

  calculaDuracao(start: string, end: string) {
    if (start && end) {
      const [hhStart, mmStart, ssStart] = start.split(":");
      const [hhEnd, mmEnd, ssEndt] = end.split(":");

      let minutosStartTotal =
        Number(hhStart) * 60 + Number(mmStart) + Number(ssStart) / 60;
      let minutosEndTotal =
        Number(hhEnd) * 60 + Number(mmEnd) + Number(ssEndt) / 60;

      if (minutosStartTotal > minutosEndTotal) {
        minutosStartTotal = minutosStartTotal - 720;
        minutosEndTotal = minutosEndTotal + 720;
      }

      minutosStartTotal = minutosStartTotal * 60;
      minutosEndTotal = minutosEndTotal * 60;

      let segundos = Math.abs(minutosEndTotal - minutosStartTotal);
      let segundo = segundos % 60;
      let minutos = segundos / 60;
      let minuto = minutos % 60;
      let hora = minutos / 60;

      const horaStr =
        Math.trunc(hora) <= 9
          ? "0" + Math.trunc(hora).toString()
          : Math.trunc(hora).toString();
      const minutoStr =
        Math.trunc(minuto) <= 9
          ? "0" + Math.trunc(minuto).toString()
          : Math.trunc(minuto).toString();
      const segundosStr =
        Math.trunc(segundo) <= 9
          ? "0" + Math.trunc(segundo).toString()
          : Math.trunc(segundo).toString();

      return horaStr + ":" + minutoStr + ":" + segundosStr;
    } else {
      return null;
    }
  }

  changeCalendar() {
    if (this.selectedGrade.id && this.selectedUnit.id) {
    }
  }

  checkGreenDays() {
    for (const prof of this.greenProfessionals) {
      let profSearch = this.healthUnitsProfissionais.find(
        (profissional) => profissional.name === prof.health_professional_name
      );

      const startDate = (
        prof?.adjusted_start_datetime || prof?.start_datetime
      ).split("T");

      const [yyyy, mm, dd] = startDate[0].split("-");
      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.rowWeek[mes].semana[semana].dias.length;
            dias++
          ) {
            if (
              Number(this.rowWeek[mes].semana[semana].dias[dias].dia) >
              Number(dd)
            ) {
              for (
                var profissional = 0;
                profissional <
                this.rowWeek[mes].semana[semana].dias[dias].professionais
                  .length;
                profissional++
              ) {
                if (
                  (!this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].status &&
                    !this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].register &&
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id === profSearch.health_professional_id) ||
                  (this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].status === "COLORMISSING" &&
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id === profSearch.health_professional_id)
                ) {
                  const turno = this.gradeAllData.planned_workshifts.find(
                    (turn) =>
                      turn.name === this.rowWeek[mes].semana[semana].periodo
                  );
                  let startDatePeriod = new Date(
                    this.yearSchedule +
                      "-" +
                      this.realMonth +
                      "-" +
                      (Number(this.rowWeek[mes].semana[semana].dias[dias].dia) >
                      9
                        ? "0" +
                          Number(
                            this.rowWeek[mes].semana[semana].dias[dias].dia
                          )
                        : Number(
                            this.rowWeek[mes].semana[semana].dias[dias].dia
                          )) +
                      "T" +
                      turno.start_time
                  );
                  if (isAfter(new Date(), startDatePeriod)) {
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ] = {
                      id: profSearch.health_professional_id,
                      id_register: profSearch.id,
                      health_professional_name: profSearch.name,
                      profile: profSearch.profile,
                      periodo: profSearch.periodo,

                      start_datetime: turno.start_time.substring(0, 5),
                      total: null,
                      status: "INPROGRESS",
                    };

                    const indexRemove = this.externalSchedule.findIndex(
                      (registerFiltered) =>
                        registerFiltered.health_professional_name ===
                          prof.health_professional_name &&
                        registerFiltered.health_unit_name ===
                          prof.health_unit_name &&
                        registerFiltered.adjusted_worked_hours ===
                          prof.adjusted_worked_hours &&
                        registerFiltered.worked_hours === prof.worked_hours
                    );

                    this.externalSchedule.splice(indexRemove, 1);
                    this.externalConsolidatedShift++;
                    profissional =
                      this.rowWeek[mes].semana[semana].dias[dias].professionais
                        .length;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  removeProf(professionalCheck, dia, periodo) {
    var texto = "Deseja remover esse registro?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        let prof = this.healthUnitsProfissionais.find(
          (profissional) =>
            profissional.health_professional_id === professionalCheck.start_id
        );

        for (var mes = 0; mes < this.rowWeek.length; mes++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              for (
                var profissional: number =
                  this.rowWeek[mes].semana[semana].dias[dias].professionais
                    .length - 1;
                profissional >= 0;
                profissional--
              ) {
                const hasProf = this.rowWeek[mes].semana[semana].dias[
                  dias
                ].professionais.find(
                  (prof) => prof.id === prof.health_professional_id
                );
                if (
                  prof &&
                  this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                  this.rowWeek[mes].semana[semana].periodo === periodo &&
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id === professionalCheck.health_professional_id
                ) {
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ] = {
                    id: prof.health_professional_id,
                    start_id: prof.health_professional_id,
                    health_professional_name: prof.name,
                    profile: prof.profile,
                    periodo: prof.periodo,
                  };

                  const data = {
                    health_professional_name: prof.name,
                    adjusted_start_datetime: professionalCheck.register
                      .startProfDate
                      ? this.formatDate(
                          professionalCheck.register.startProfDate?.toISOString()
                        )
                      : null,
                    adjusted_end_datetime: professionalCheck.register
                      .endProfDate
                      ? this.formatDate(
                          professionalCheck.register.endProfDate?.toISOString()
                        )
                      : null,
                    adjusted_worked_hours: this.calculaDuracao(
                      professionalCheck.register.start,
                      professionalCheck.register.end
                    ),
                    start_datetime: professionalCheck.register
                      .inicialStartDatetime
                      ? this.formatDate(
                          professionalCheck.register.inicialStartDatetime
                        )
                      : null,
                    end_datetime: professionalCheck.register.inicialEndDatetime
                      ? this.formatDate(
                          professionalCheck.register.inicialEndDatetime
                        )
                      : null,
                    worked_hours:
                      professionalCheck.register.adjusted_worked_hours ||
                      professionalCheck.register.worked_hours,
                    period: professionalCheck.register,
                    type: "split",
                    id: professionalCheck.id,
                    registro: professionalCheck.register.isSplit
                      ? "Split Automático"
                      : null,
                    isSplit: professionalCheck.register.isSplit,
                  };

                  this.externalSchedule.push(data as any);
                  this.sortExternal();
                }
              }
            }
          }
        }
      }
    });
  }

  openModalScheduleMonitoring(day, period) {
    const review = this.externalSchedule.filter((ex) => {
      const dayStart = Number(
        ex.adjusted_start_datetime
          ? this.formatDate(ex.adjusted_start_datetime)
              .split(" ")[0]
              .split("/")[0]
          : this.formatDate(ex.start_datetime).split(" ")[0].split("/")[0]
      );

      if (Number(dayStart) === Number(day)) {
        if (ex.period === period) {
          return ex;
        } else if (ex.period === null) {
          return ex;
        }
      }
    });

    const dialogRef = this.dialog.open(this.scheduleMonitoringModalComponent, {
      width: "1150px",
      height: "450px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: { externalSchedule: review },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === "CHANGE") {
        this.changeProf(result);
      } else if (result.action === "EDIT") {
        this.editProf(result);
      } else if (result.action === "REMOVE") {
        this.removeRegister(result);
      }
    });
  }
  dateFormatLocal(date?: string) {
    return dateFormat(date);
  }
  countReview(day, period) {
    const review = this.externalSchedule.filter((ex) => {
      const dayStart = Number(
        ex.adjusted_start_datetime
          ? this.formatDate(ex.adjusted_start_datetime)
              .split(" ")[0]
              .split("/")[0]
          : this.formatDate(ex.start_datetime).split(" ")[0].split("/")[0]
      );
      if (Number(dayStart) === Number(day)) {
        if (ex.period === period) {
          return ex;
        } else if (ex.period === null) {
          return ex;
        }
      }
    });
    return review.length;
  }

  sortExternal() {
    this.externalSchedule.sort(function (a, b) {
      let oneDate = null;
      let secondDate = null;
      if (a.adjusted_start_datetime) {
        const dateFormatStart = a.adjusted_start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        oneDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      } else if (a.start_datetime && !a.adjusted_start_datetime) {
        const dateFormatStart = a.start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        oneDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      }

      if (b.adjusted_start_datetime) {
        const dateFormatStart = b.adjusted_start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        secondDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      } else if (b.start_datetime && !b.adjusted_start_datetime) {
        const dateFormatStart = b.start_datetime.split(" ");

        const [day, month, year] = dateFormatStart[0].split("/");
        secondDate = new Date(
          year +
            "-" +
            month +
            "-" +
            (Number(day) <= 9 ? "0" + Number(day) : Number(day)) +
            "T" +
            (dateFormatStart.length === 2
              ? dateFormatStart[1]
              : dateFormatStart[2])
        );
      }

      if (isAfter(oneDate, secondDate)) {
        return 1;
      }
      if (isAfter(secondDate, oneDate)) {
        return -1;
      }
      return 0;
    });
  }
}
