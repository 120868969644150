import { Component, OnInit, HostListener } from "@angular/core";

import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { ModalService } from "src/app/_services/modal.service";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { CreateGridComponent } from "../create-grid/create-grid.component";
import { CreateScheduleComponent } from "../create-schedule/create-schedule.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogEditComponent } from "../dialog-edit/dialog-edit.component";
import { setDate, subHours, set } from "date-fns";
import { ScheduleService } from "src/app/_services/schedule.service";
import { GridService } from "src/app/_services/grid.service";

@Component({
  selector: "medlog-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
})
export class ScheduleComponent implements OnInit {
  createdDate: any;
  updatedData: any;
  monthData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private healthUnitService: HealthUnitService,
    public dialog: MatDialog,
    private healthProfessionalService: HealthProfessionalsService,
    private scheduleService: ScheduleService,
    private gridService: GridService,
    private modalService: ModalService
  ) {
    document.getElementById("menu").click();
  }

  text: string;
  initial: any;
  healthUnits;
  selectedUnit;
  loading = true;
  gradeData;
  selectedGrade;
  visualizarData = [];
  selectedVisualizar;
  gradeAllData;
  title;
  dialogs: DetailRegistroComponent;
  allGrid;
  gradeSchedule: any = [];
  pt: any;
  unitId;
  gridId;
  date;

  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  enableCreate = true;
  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      console.log(params);
      this.unitId = params["selectedUnit"];
      this.gridId = params["selectedGrade"];
      this.date = params["modelDataInicio"];
    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
    this.title = localStorage.getItem("titulo");

    let dat = { results: [] };
    this.gradeData = dat;
    this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    this.selectedGrade = this.gradeData.results[0];
    this.visualizarData.push({ name: "Selecione a Visualização", id: 0 });
    this.visualizarData.push({ name: "Todas", id: 1 });
    this.visualizarData.push({ name: "Ativas", id: 2 });
    this.visualizarData.push({ name: "Inativas", id: 3 });

    const params = {
      health_unit_status: "enable",
    };

    this.healthUnitService.getHealthUnits(params).subscribe(async (data) => {
      this.loading = false;
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });
      if (this.unitId) {
        const findUnit = this.healthUnits.results.find(
          (unit) => unit.id === this.unitId
        );
        if (findUnit) {
          this.selectedUnit = findUnit;
          await this.getGrid();
        } else {
          this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
        }
      } else {
        this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
      }
      //this.healthUnits.results.reverse();
    });
  }

  getAllGrids() {
    if (this.selectedGrade.id == 0) {
      this.openDialogMsg("Selecione uma grade!");
    } else {
      if (this.modelDataInicio == "") {
        this.openDialogMsg("Selecione um mês!");
      } else {
        this.gradeAllData = "";
        this.gradeSchedule = [];
        //this.gradeAllData = this.selectedGrade;
        this.loading = true;

        const dateFormat = this.modelDataInicio.toISOString().split("T");

        this.scheduleService
          .getSchedule(
            this.selectedGrade.id,
            this.selectedUnit.id,
            dateFormat[0],
            true
          )
          .subscribe(
            (data) => {
              if (!data) {
                this.enableCreate = true;
                this.openDialog(
                  "Essa escala não foi criada para o período selecionado!"
                );
                this.loading = false;
                return;
              }

              this.loading = false;
              // var datas = new Date(this.modelDataInicio);

              // //this.gradeSchedule = data["results"][0];
              // var dtDb = datas.toISOString().split("T");
              // const filter = data["results"].filter(
              //   (element) => element.month == dtDb[0]
              // );
              if (data) {
                this.enableCreate = false;

                this.gradeSchedule = data;
                console.log(this.gradeSchedule, "data");
                if (
                  this.gradeSchedule.current_grid &&
                  this.selectedGrade.history.length > 0
                ) {
                  this.gradeAllData = this.selectedGrade.history.find(
                    (grid) => grid.id === this.gradeSchedule.current_grid
                  );
                } else {
                  this.gradeAllData = this.selectedGrade;
                }

                for (
                  var i = 0;
                  i < this.gradeAllData.planned_workshifts.length;
                  i++
                ) {
                  this.calculateHour(i);
                }

                this.monthData = this.gradeSchedule.month.split("-");
                this.monthData[1] = this.pt.monthNames[this.monthData[1] - 1];
                this.createdDate = this.formatDate(
                  this.gradeAllData.created_at
                );
                this.updatedData = this.formatDate(
                  this.gradeAllData.updated_at
                );
              } else {
                this.enableCreate = true;
              }
            },
            (err) => {
              this.enableCreate = true;
              this.openDialog(
                "Essa escala não foi criada para o período selecionado!"
              );
              this.loading = false;
              return;
            }
          );
      }
    }
  }

  getGrid() {
    this.gradeAllData = "";
    if (this.selectedUnit.id == 0) {
      let dat = { results: [] };
      this.gradeData = dat;
      this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    } else {
      this.gridService
        .getGrids(this.selectedUnit.id)
        .subscribe(async (data) => {
          this.gradeData = data;

          this.gradeData.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.gradeData.results.unshift({ name: "Selecione a Grade", id: 0 });

          if (this.gridId) {
            const findGrid = this.gradeData.results.find(
              (grid) => grid.id === this.gridId
            );
            if (findGrid) {
              this.selectedGrade = findGrid;

              if (this.date) {
                console.log(this.date);
                const [yyyy, mm, dd] = this.date.split("-");
                console.log(yyyy, mm, dd);
                this.modelDataInicio = new Date(
                  yyyy + "-" + mm + "-" + dd + "T01:00:00"
                );
                await this.getAllGrids();
              }
            }
          }
        });
    }
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatDate(data: string) {
    if (data != null) {
      var array = data.split("");
      var year = array[0] + array[1] + array[2] + array[3];
      var month = array[5] + array[6];
      var day = array[8] + array[9];
      var hour = array[11] + array[12];
      var hourInt = parseInt(hour) - 3;

      if (hourInt < 0) {
        hourInt = hourInt + 24;
      }
      var hour = hourInt.toLocaleString();
      var minute = array[14] + array[15];
      return day + "/" + month + "/" + year + "  " + hour + ":" + minute;
    }
  }

  formatDatePT(data: Date) {
    return data.toLocaleDateString();
  }

  async openGrade() {
    if (this.enableCreate) {
      const dialogRef = this.dialog.open(CreateScheduleComponent, {
        width: "500px",
        height: "350px",
        panelClass: "azulPanel",
        //disableClose: true,
        data: { description: "", selectUnity: "" },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.loading = true;
          this.scheduleService.createSchedule(result).subscribe(
            async (data) => {
              const unit = await this.healthUnits.results.filter(
                (unit) => unit.id === result.unit.id
              );

              this.selectedUnit = { ...unit[0] };
              await this.getGrid();
              this.selectedGrade = { ...result.grid_data };
              this.loading = false;
              this.modelDataInicio = result.dateMonth;
              this.openDialogSucessoCadastro("Escala criada com sucesso!");
              this.getAllGrids();
            },
            (error) => {
              this.loading = false;
              this.openDialog("Ocorreu um erro, tente novamente!");
              return error;
            }
          );
        }
      });
    } else {
      this.openDialogSucesso("Escala já Existente para esse mês");
    }
  }

  onKeyDate(event: any) {
    event.target.value = event.target.value.replace(
      /^(\d{2})(\d{2})(\d{4})/,
      "$1/$2/$3"
    );
  }

  verifyDate(valor) {
    valor.action = true;
  }

  openDialogMsg(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogSucessoCadastro(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAllGrids();
    });
  }

  deleteGrid() {
    var texto = "Deseja excluir a grade?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        //ok
        this.loading = true;
        this.gridService.deleteGrid(this.selectedGrade.id).subscribe((data) => {
          this.loading = false;
          this.openDialogSucesso("Grade excluída com sucesso!");
          this.getGrid();
        });
      } else {
        //cancel
      }
    });
  }

  clearTable() {
    this.gradeAllData = "";
  }

  editGrid() {
    const dialogRef = this.dialog.open(CreateGridComponent, {
      width: "900px",
      height: "450px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: { description: "", selectUnity: this.gradeAllData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      /*if(result){
          this.loading = true;
          this.healthProfessionalService.sendGrid(result).subscribe(
            data => {
              this.loading = false;
              this.openDialogSucesso("Grade criada com sucesso!")
      
            }, error => {
              this.loading = false;
              this.openDialog("Ocorreu um erro, tente novamente!");
              return error;
            }  
          );
        }*/
    });
  }

  openEscala(gradeSchedule) {
    this.router.navigate(
      ["/home/escala/" + gradeSchedule.grid + "/" + gradeSchedule.id],
      {
        queryParams: {
          month: this.gradeSchedule.month,
          current_grid: this.gradeSchedule.current_grid
            ? this.gradeSchedule.current_grid
            : null,
          gradeSchedule: JSON.stringify(this.gradeSchedule),
          selectedUnit: this.selectedUnit.id,
          selectedGrade: this.selectedGrade.id,
          modelDataInicio: this.modelDataInicio.toISOString().split("T")[0],
        },
      }
    );
  }

  deleteEscala(gradeSchedule) {
    var texto = "Deseja desabilitar a escala?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        //ok
        this.loading = true;
        this.scheduleService
          .deleteEscala(gradeSchedule.id)
          .subscribe((data) => {
            this.loading = false;
            this.openDialogSucesso("Escala excluída com sucesso!");
            this.getGrid();
          });
      } else {
        //cancel
      }
    });
  }
  calculateHour(position) {
    if (
      this.gradeAllData.planned_workshifts[position].start_time != "" &&
      this.gradeAllData.planned_workshifts[position].duration != ""
    ) {
      var today = new Date();
      var splitHora =
        this.gradeAllData.planned_workshifts[position].start_time.split(":");
      var starts =
        this.gradeAllData.planned_workshifts[position].duration.split(":");
      var hh;
      if (splitHora[0] < 10) {
        hh = Number(splitHora[0].toString());
      } else {
        hh = splitHora[0];
      }
      today.setHours(hh, 0, 0);

      var jj;
      if (starts[0] < 10) {
        jj = Number(starts[0].toString());
      } else {
        jj = starts[0];
      }

      today.setHours(Number(today.getHours()) + Number(jj));
      this.gradeAllData.planned_workshifts[position].horaFim = this.somartempos(
        this.gradeAllData.planned_workshifts[position].start_time,
        this.gradeAllData.planned_workshifts[position].duration + ":00"
      );
    }
  }
  editValue() {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: "450px",
      height: "200px",
      panelClass: "blackPanel",
      data: {
        enableCancel: true,
        escala: this.gradeSchedule,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllGrids();
        this.openDialogSucesso("Escala alterada com sucesso!");
      }
    });
  }
  somartempos(tempo1, tempo2) {
    if (tempo1 && tempo2) {
      var array1 = tempo1.split(":");

      var tempo_seg1: any =
        parseInt(array1[0]) * 3600 +
        parseInt(array1[1]) * 60 +
        parseInt(array1[2]);

      var array2 = tempo2.split(":");

      var tempo_seg2: any =
        parseInt(array2[0]) * 3600 +
        parseInt(array2[1]) * 60 +
        parseInt(array2[2]);

      var tempofinal = parseInt(tempo_seg1) + parseInt(tempo_seg2);

      var hours = Math.floor(tempofinal / (60 * 60));

      if (hours > 24) hours = hours - 24;

      var divisorMinutos = tempofinal % (60 * 60);

      var minutes = Math.floor(divisorMinutos / 60);
      if (minutes > 60) {
        minutes = minutes - 60;
        hours = hours + 1;
      }

      var divisorSeconds = divisorMinutos % 60;

      var seconds = Math.ceil(divisorSeconds);

      var contador = "";

      if (hours < 10) {
        contador = "0" + hours + ":";
      } else {
        contador = hours + ":";
      }

      if (minutes < 10) {
        contador += "0" + minutes + ":";
      } else {
        contador += minutes + ":";
      }

      if (seconds < 10) {
        contador += "0" + seconds;
      } else {
        contador += seconds;
      }

      return contador;
    }
  }
}
